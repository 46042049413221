import magnifyglass from "../../assets/images/MagnifyingGlass.svg";
import download from "../../assets/images/DownloadSimple.svg";
import { PiWallet } from "react-icons/pi";
import Dropdown from "react-bootstrap/Dropdown";
import sort from "../../assets/images/sortbtn.svg";
import "../UserManagement/UserManagement.scss";
import "./Users.scss";
import { useEffect, useState } from "react";
import { TfiEmail, TfiTwitterAlt } from "react-icons/tfi";
import { IoLogoDiscord } from "react-icons/io5";
import { toast } from "react-toastify";
import PaginationComponent from "../Pagination";
import { downloadCSV, fullDateFormat, showSlicedText } from "../../utils/common";
import { get, put } from "../../utils/api/apiHelper";


function Users() {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    try {
      let response = await get(
        `/admin/userslist?search=${search}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false&exportEmail=false`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        setItems(response.data.items);
        setTotal(response.data.totalItems);
      } else {
        toast.error("Failed to fetch data!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const block_unblock_user = async (_id, isBlocked) => {
    setLoading(true);
    toast.dismiss();
    try {
      let response = await put(
        `/admin/user_block_or_unblock`,
        { _id, isBlocked },
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        toast.success(response?.message);
        getUsers();
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedUsers?.length !== items?.length) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [selectedUsers, items]);

  const handleSelectAll = () => {
    setChecked((prev) => !prev);
    if (!checked) {
      setSelectedUsers(items.map(({ _id }) => _id));
    } else {
      setSelectedUsers([]);
    }
  };

  const selectHandler = (_id) => {
    console.log(_id, "select handler");
    if (selectedUsers.includes(_id)) {
      console.log(_id, "removed");
      const users = [...selectedUsers];
      setSelectedUsers(users.filter((currId) => currId !== _id));
    } else {
      console.log(_id, "added");
      setSelectedUsers((prev) => [...prev, _id]);
    }
  };

  const downloadUsersCsv = async () => {
    try {

      if (selectedUsers.length < 1) {
        toast.error("Please select atleast one user to download");
        return;
      }
      console.log(JSON.stringify(selectedUsers));
      let response = await get(
        `/admin/userslist?search=${search}&selectedUsers=${selectedUsers.join(',')}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=true&exportEmail=false`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        downloadCSV(response?.data, "Users_list");
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const downloadUsersEmail = async () => {
    try {
      console.log(">>>>>>>>>>");
      let response ;
      if (selectedUsers.length < 1) {
        response = await get(
          `admin/userslist?pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false&exportEmail=true`,
          localStorage.getItem("accessToken")
        );
      }else{
        response = await get(
          `admin/userslist?selectedUsers=${selectedUsers.join(',')}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false&exportEmail=true`,
          localStorage.getItem("accessToken")
        );

      }
      console.log(response,"response>>>>>>>>>");
      if (response.status === 200) {
        downloadCSV(response?.data, "UsersEmail_list");
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, search]);

  if (loading) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <div className="custom-loader"></div>
      </div>
    );
  }

  return (
    <div className="main-inner">
      <div className="mt-4">
        <div className="bg-dark-1 pb-4">
          <div className="">

            <div className="row mx-0">
              <div className=" col-md-4">
                <div className="mb-3 d-flex">
                  <div className=" position-relative w-100">
                    <input
                      type="text  "
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      className="bg-transparent border-0 w-100 py-2  ps-5 text-white"
                      placeholder="searchbar"
                    />
                    <div className="search-icon">
                      <img src={magnifyglass} alt="" />
                    </div>
                  </div>

                  <div className="col-4 text-end d-md-none d-block">
                    <button
                      className="bg-transparent border_gray text-white border_radius p-2"
                      onClick={downloadUsersCsv}
                    >
                      <img src={download} alt="" className=" " />
                      <span className="size">csv</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-8  ">
                <div className="d-flex justify-content-end">
                  {/* <button className="bg-transparent border_gray text-white p-2 border_radius ">
              <img src={filter} alt="" className="text-white" /> <span className="size">filter </span></button> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="bg-transparent border_gray p-2 drop-btn border_radius"
                    >
                      <span>
                        <img src={sort} width={20} alt="" className=" " />
                      </span>
                      <span className="size">filters </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="bg-dark-1 border_gray  border_radius">
                      <Dropdown.Item
                        href="#"
                        className="text-white font12 p-3 "
                      >
                        <span className="one1">
                          Sort the users by date joined
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="text-white font12 py-2 "
                      >
                        <span className="one1">Eth bought</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="text-white font12 py-2"
                      >
                        <span className="one1">Eth won, etc.</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="text-white font12 py-2 "
                      >
                        <span className="one1">filter by role</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button className="bg-transparent border_gray text-white ms-3 border_radius ">
                    <img src={download} alt="" className=" " />{" "}
                    <span className="size" onClick={downloadUsersEmail}>download email</span>{" "}
                  </button>
                  <button
                    className="bg-transparent border_gray text-white border_radius d-md-block d-none ms-3"
                    onClick={downloadUsersCsv}
                  >
                    <img src={download} alt="" className=" " />{" "}
                    <span className="size">csv</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="row mx-0 py-4 battle-enter mt-3 ">
              <div className="col-6 col-md-2 ">
                <p className="text-gray mb-0  d-flex ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    data-toggle="switchbutton"
                    onChange={handleSelectAll}
                  ></input>
                  <span className="px-2 pt-0">username</span>
                </p>
              </div>
              <div className=" col-6 col-md-2 text-center">
                <p className="text-gray mb-0">wallet address</p>
              </div>

              <div className="col-md-1 text-center d-none d-md-block">
                <p className="text-gray mb-0">eth won</p>
              </div>

              <div className="col-md-1 text-center d-none d-md-block">
                <p className="text-gray mb-0">user role</p>
              </div>

              <div className="col-md-1 text-end d-none d-md-block">
                <p className="text-gray mb-0">eth bought</p>
              </div>

              <div className="col-md-2 text-end d-none d-md-block">
                <p className="text-gray mb-0">user email</p>
              </div>

              <div className="col-md-3 text-end d-none d-md-block">
                <p className="text-gray mb-0">verification</p>
              </div>
            </div>
            <div>
              {items.map((item) => {
                return (
                  <div
                    className="border_gray d-inline-block  w-100 rounded-4 mb-3 clearfix justify-content-center  "
                    key={item._id}
                  >
                    <div className="bg-dark-1 rounded-4  ">
                      <div className="row mx-0 align-items-center py-3 ">
                        <div className=" col-9 col-md-2 mb-3 mb-md-0">
                          <div className="battle-enter-content ">
                            <p className="text-gray d-flex mb-0 ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedUsers.includes(item._id)}
                                onChange={() => selectHandler(item._id)}
                              ></input>
                              <span className="px-2  mb-0 pb-0 ">
                                {item.userName}{" "}
                                <p className="mb-0 pb-0">

                                  {fullDateFormat(item.createdAt)}
                                </p>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-3 col-md-2 text-end text-md-center  mb-md-0 ">
                          <p className=" text-cyan mb-0 text-truncate">
                            {showSlicedText(item.walletAddress)}
                          </p>
                        </div>

                        <div className="col-6 d-md-none  mb-md-0">
                          <p className="text-gray mb-0">eth won</p>
                        </div>
                        <div className="col-md-1 col-6 text-end text-md-center mb-0 mb-md-0">
                          <p className="text-white d-inline-block text-truncate pt-2 text-elips mb-0">
                            {(+item?.eth_won) > 0 ? (+item?.eth_won).toFixed(4) : 0}
                          </p>
                        </div>
                        <div className="col-6 d-md-none mb-3 mb-md-0">
                          <p className="text-gray mb-0">user role</p>
                        </div>
                        <div className="col-6 col-md-1 text-md-end text-end  mb-md-0 pt-md-0 ">
                          <span
                            className="text-gray border_gray p-2 rounded-3 font12 inner-content "
                          >
                            {'user'}
                          </span>
                        </div>

                        <div className="col-6 d-md-none mb-3 mb-md-0">
                          <p className="text-gray mb-0">eth bought</p>
                        </div>
                        <div className="col-6 col-md-1 text-md-end  mb-0 text-end  pt-md-0 mb-md-0">
                          <p className="text-gray mb-0"> {(+item?.eth_bought) > 0 ? (+item?.eth_bought).toFixed(4) : 0}</p>
                        </div>

                        <div className="col-6 d-md-none mb-3 mb-md-0">
                          <p className="text-gray mb-0">user email</p>
                        </div>

                        <div className="col-6 col-md-2 mb-0 pt-md-0  ">
                          <p className="text-cyan mb-0 text-truncate">
                            {item.email}
                          </p>
                        </div>

                        <div className="col-6 d-md-none mb-3 mb-md-0 ">
                          <p className="text-gray mb-0">verification</p>
                        </div>
                        <div className="col-6 col-md-3 text-md-end text-end  pt-md-0 mb-3 mb-md-0 ">
                          <TfiEmail color={item?.isEmailVerified ? "white" : ""} fontSize={12} className="mx-1" />
                          <PiWallet color={item?.walletAddress ? "white" : ""} fontSize={14} className="mx-1" />
                          <TfiTwitterAlt color={item?.isTwitterVerified ? "white" : ""} fontSize={14} className="mx-1" />
                          <IoLogoDiscord color={item?.isDiscordVerified ? "white" : ""} fontSize={16} className="mx-1" />
                          <span className="px-3 text-md-center">
                            <button
                              className="text-white bg-transparent  border_gray p-2 rounded-3 font12 px-3"
                              onClick={() => block_unblock_user(item._id, !item.isBlocked)}
                            >
                              {item.isBlocked ? "unblock" : "block"}
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="pb-4 mt-4 ">
                {total > 0 && (
                  <PaginationComponent
                    className="mx-auto"
                    itemsCount={total}
                    itemsPerPage={perPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
