import React, { useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/userSlice';
import { setLoading } from "../../redux/slices/commonSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./Login.scss";
import { post } from '../../utils/api/apiHelper';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/adminlogo.svg'
function Login() {
  const [eye, setEye] = useState({ p: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("plz provide valid email address.").required("email is required."),
      password: Yup.string()
        .required("password is required.")
        .min(6, "password is too short - should be 6 chars minimum.")
        .matches(/[a-zA-Z0-9]/, "password can only contain latin letters."),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const response = await post('/admin/login', values);
        dispatch(setLoading(false));
        if (response.status === 200) {
          dispatch(login(response.data));
          localStorage.setItem('accessToken', response.data.token);
          toast.success(response?.message);
          navigate('/');
        } else {
          toast.error(response?.error ?? "Something went wrong.");
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Failed To Login!');
      }
    }
  });
  return (
    <div className='bg-dark-1 d-flex justify-content-center align-items-center vh-100'>

      <div className=" login text-center">
        <img src={logo} alt="" width={80} className='py-2' />
        <div className="edit-profile  rounded-5 mt-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="form-group mb-3">
                <label className="text-white">Email</label>
                <input placeholder="Enter Email" type="email" name='email' className="form-control font_family"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-danger">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              <div className="form-group">
                <label className="text-white">Password</label>
                <div className="position-relative">
                  <input placeholder="Enter Password" type={eye.p ? "text" : "password"} name='password' className="bg-transparent form-control font_family"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <span
                    className={`passeye position-absolute`}
                    onClick={() => setEye({ ...eye, p: !eye.p })}
                  >
                    {!eye.p ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-danger">
                    {formik.errors.password}
                  </p>
                ) : null}

              </div>

              <div className='text-end mt-2'>
                <Link to="/forgot-password" className='text-primary '>Forgot Password?</Link>
              </div>

              <div className='mt-3'>
                <button type="submit" className="cursor-pointer btn btn-default shadow-0 py-2 w-100" >
                  <span className="text-gradient">Login</span> </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login