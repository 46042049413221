import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
    name: 'User',
    initialState: {
        email: '',
        username:'',
        isLogin: false,
        accessToken:''
    },
    reducers: {
        login: (state, action) => {
                state.isLogin = true;
                state.accessToken = action.payload.token;
                state.email = action.payload.email;
                state.username = action.payload.firstName;
        },
        logOut: (state) => {
               state.isLogin = false;
                state.email = '';
                state.accessToken = '';
                state.username = '';
        }
    },
})

export const { login, logOut } = loginSlice.actions

export default loginSlice.reducer;