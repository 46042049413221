import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from "react-toastify";
import { post, get, put, postAsForm } from "../../../utils/api/apiHelper"
import "./updatepoints.scss";

const UpdatePoints = () => {
  const [data, setData] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    getAdminSetting();
  }, []);



  const getAdminSetting = async () => {
    try {
      const response = await get(
        `/admin/get_settings`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        setData(response.data);
        //console.log("data========", data);
      } else {
        console.error("Failed to fetch data!");
      }
    } catch (error) {
      console.error("Something went wrong!", error);
    }
  };

  const handleUpdateSettings = async () => {
    try {

        if (!value) {
            toast.error("Field cannot be Empty!");
            return; 
          }
      const payload = {
        key: selectedKey,
        value: value,
      };

      const res = await put(
        `/admin/update/setting`,
        payload,
        localStorage.getItem("accessToken")
      );

      if (res.status === 200) {
        toast.success("Settings updated successfully!");
        getAdminSetting();
      } else {
        console.error("Failed !");
      }
    } catch (error) {
      console.error("Error updating settings", error);
      toast.error("Error updating settings");
    }
  };

  return (
    <div>
      <div className="colorback rounded-4 ">
        <div className="row p-5">
          <div className="col-md-4 mt-3">
            <div className="">
              <label htmlFor="" className="text-white h4 ">
                Update Settings
              </label>
              <Form.Select
              className="mt-3 "
                aria-label="Default select example"
                size="md"
                type="select"
                name="selectKey"
                id="selectKey"
                value={selectedKey}
                onChange={(e) => setSelectedKey(e.target.value)}
              >
                <option value=""> Select Options </option>
                <option className="" value="referral_points">
                  Referral Points{" "}
                </option>
                <option value="lottery_buy_points">Lottery Buy Points</option>
                <option value="lottery_winning_points">
                  Lottery Winning Points
                </option>
                <option value="email_verification_points">
                  Email Verification Points
                </option>

                <option value= "streak_points">
                Streak Points 
                </option>

             
              </Form.Select>
              <input
                className="form-control  border_gray mt-4"
                placeholder=""
                type="number"
                name="value"
                id="value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />

              <button
                className="btn btn-default update-btn mt-3 py-3 "
                type="submit"
                onClick={handleUpdateSettings}
              >
                <span className="text-gradient">Update</span>
              </button>
            </div>
          </div>

          <div className="col-md-8 mt-3">
            <div className=" ">
              <h4 className="text-light ">Updated Value </h4>

              <div className="text-white mt-4">
                <ListGroup className=" ">
                  {data.map((doc) => {
                    return (
                      <ListGroup.Item key={doc?.key} className="bg-transparent text-white text-break">
                        {doc?.key?.split("_")?.join(" ")} : {doc.value}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePoints;
