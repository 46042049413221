import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { put } from '../../utils/api/apiHelper';
import { toast } from 'react-toastify';

const EditModal = (props) => {
    const [item, setItem] = useState(props?.item);
    const editBattle = async (e) => {
        e.preventDefault();
        console.log(item, "item");
        try {
            const params = {
                _id: props?.item?._id,
                name: item?.name,
                ticket_price: item?.ticket_price
            };
            const response = await put(
                `/admin/update/battle`,
                params,
                localStorage.getItem("accessToken")
            );
            if (response.status === 200) {
                toast.success("Battle Edited Successfully!");
                props.getBattlelist()
                props.onHide();
            } else {
                console.error("Failed to edit battle");
                toast.error(`${response?.error}`);
            }
        } catch (error) {
            console.error("Something went wrong!", error);
            toast.error("Something went wrong!");
        }
    }
    const handleChange = (e) => {
        // setItem({ [e.target.name]: e.target.value })
        setItem((prevItem) => ({
            ...prevItem,
            [e.target.name]: e.target.value,
        }));
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className=''>
                <div className="">
                    <div className="  ">
                        <div className="row ">
                            <div className="col-md-8 mx-auto text-white inner-content px-5 py-4 ">
                                <Button variant="link" onClick={props.onHide} className='text-decoration-none float-end  light-text text-white '>X</Button>
                                <form onSubmit={editBattle}>
                                    <h3 className="fs-3 text-center text-white py-4 mt-4">
                                        Edit Battle Details
                                    </h3>
                                    <div className="mt-3 ">
                                        <ListGroup className='bg-transparent'>
                                            <ListGroup.Item className='bg-transparent text-white'>Name :  <input type='text' name='name' value={item?.["name"]} onChange={handleChange} required /></ListGroup.Item>
                                            <ListGroup.Item className='bg-transparent  text-white'>Ticket Price : <input name='ticket_price' value={item?.["ticket_price"]} onChange={(e) => {
                                                if (!Number.isNaN(Number(e.target.value))) {
                                                    handleChange(e)
                                                }
                                            }} required /></ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <div className="text-center">
                                        <Button type='submit' className="px-4 py-2 m-3 approve border-0">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditModal