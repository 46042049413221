import React from "react";
import Button from 'react-bootstrap/Button';
import "./Verificationpopup.scss";

import Modal from 'react-bootstrap/Modal';


function Verificationpopup(props) {

  return (

    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body className='  '>

        <div className="">

          <div className="  ">
            <div className="row ">
              <div className="col-md-8 mx-auto text-white inner-content px-5 py-4 ">

                <Button variant="link" onClick={props.onHide} className='text-decoration-none float-end  light-text text-white '>X</Button>
                <h3 className="fs-3 text-center text-white py-4 mt-4">
                  Answers on questions
                </h3>

                <div className="mt-3 ">
                  {
                    props?.answers?.map((answer, i) => {
                      return (
                        <div className="mb-1 ">
                          <label htmlFor="Textarea1">
                            <h5 className="formheading  text-white py-3">
                              {i + 1}. {answer?.question}
                            </h5>
                          </label>
                          <div className="rounded-3">
                            <div className="position-relative ">
                              <textarea
                                value={answer?.answer}
                                disabled
                                className="form-control border bg-transparent  text-white"
                                rows="2"
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="text-center">
                    <Button className="px-4 py-2 m-3 approve border-0" onClick={() => props.updateStatus("APPROVED")}>approve</Button>
                    <Button className="px-5 py-2 deny border-0" onClick={() => props.updateStatus("DECLINED")}>deny</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Verificationpopup;
