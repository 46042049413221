import './History.scss'
import eth from "../../assets/images/ethlogo.svg";
// import share from "../../assets/images/share.svg";
import { get } from "../../utils/api/apiHelper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PaginationComponent from "../Pagination";
import { fullDateFormat } from '../../utils/common';

function WinnerHistory() {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(10);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const getWinners = async () => {
        try {
            let response = await get(`/admin/get_winners?&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false`,
                localStorage.getItem("accessToken")
            );
            if (response.status === 200) {
                setItems(response.data.items);
                setTotal(response.data.totalItems);
            } else {
                toast.error("Failed to fetch data!");
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getWinners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    if (loading) {
        return (
            <div className="d-flex vh-100 justify-content-center align-items-center">
                <div className="custom-loader"></div>
            </div>
        );
    }

    return (
        <>
            <div className="bg-dark-1 mt-4">
                <div className=" main-inner">
                    <div className="">
                        <div className="row mx-0 py-4">
                            <div className=" col-6 col-md-2">
                                <p className="text-gray mb-0">Winner</p>
                            </div>
                            <div className=" col-6 col-md-2 text-center">
                                <p className="text-gray mb-0">Participants</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0">Total fighters</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0">Wallet Address</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0">Battle hash</p>
                            </div>
                            <div className="col-md-2 text-end d-none d-md-block">
                                <p className="text-gray mb-0">Date</p>
                            </div>
                        </div>
                        <div>

                            {
                                items?.map((winner) => {
                                    return (
                                        <div className="bg-gadient p-space d-inline-block  w-100 rounded-4 mb-3 clearfix" key={winner?._id}>
                                            <div className="bg-dark-1 rounded-4">

                                                <div className="row mx-0 align-items-center pt-3 mb-0">
                                                    <div className=" col-9 col-md-2 mb-3 mb-md-0">
                                                        <div className="battle-enter-content">
                                                            <p className='text-white'><small className='text-cyan'> username - </small> <small>{`${winner?.user?.userName ?? ""}`}</small></p>
                                                        <p className="text-white"><span className="text-cyan mb-0">Won <img src={eth} alt='' /> {winner?.amount_wonned?.toFixed(4)}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-3 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                    <p className="text-white"><span className="text-cyan mb-0">{winner?.battle?.participants?.length}</span></p>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Total fighters</p>
                                                </div>

                                                <div className="col-6 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                    <p className="text-gray">{winner?.battle?.total_tickets_sold}</p>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Wallet Address</p>
                                                </div>
                                                <div className="col-md-2 col-6 text-end text-md-center pt-1 mb-md-0">
                                                    <p className="text-cyan col-6 d-inline-block text-break text-truncate cursor-pointer" style={{ width: '100%' }} onClick={() => window.open(`https://sepolia.etherscan.io/address/${winner?.user?.walletAddress}`)}>{winner?.user?.walletAddress}</p>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Battle hash</p>
                                                </div>
                                                <div className="col-md-2 col-6 text-end text-md-center pt-1 mb-md-0">
                                                    <p className="text-cyan col-6 d-inline-block text-break text-truncate cursor-pointer" style={{ width: '100%' }} onClick={() => window.open(`https://sepolia.etherscan.io/address/${winner?.battle?.contractAddress}`)}>{winner?.battle?.contractAddress}</p>
                                                </div>
                                                <div className="col-md-2 text-md-end text-center pt-5 pt-md-0 ">
                                                    <p className="text-gray">{fullDateFormat(winner?.createdAt)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                        )
                                })
                            }
                    </div>
                </div>

                <div className="pb-4 mt-4 ">
                    {total > 0 && (
                        <PaginationComponent
                            className="mx-auto"
                            itemsCount={total}
                            itemsPerPage={perPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </div>


            </div>


        </div >
        </>
    )

}

export default WinnerHistory