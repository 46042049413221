import axios from "./index";

export const get = async (url, access = "") => {
  const response = await axios
    .get(url, {
      headers: {
        authorization: access,
        headers: {
          accept: "application/json",
        },
      }
    })
    .catch((err) => {
      return err.response;
    });

  return response?.data;
};

export const post = async (url, data, access = "") => {
  const response = await axios
    .post(url, data, {
      headers: {
        authorization: access,
        "Content-Type": "application/json",
      }
    })
    .catch((err) => err.response);
  return response?.data;
};

export const put = async (url, data, access = "") => {
  const response = await axios
    .put(url, data, {
      headers: {
        authorization: access,
        "Content-Type": "application/json",
      }
    })
    .catch((err) => err.response);
  return response?.data;
};

export const postAsForm = async (url, formData, access = "") => {
  const response = await axios
    .postForm(url, formData, { headers: { authorization: access } })
    .catch((err) => err.response);
  return response?.data;
};

export const remove = async (url, access = "") => {
  const response = await axios
    .delete(url, {
      headers: {
        authorization: access,
        "Content-Type": "application/json",
      }
    })
    .catch((err) => err.response);
  return response?.data

};
