import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className=" bg-dark-1  position-absolute w-100 footer ">
      <div className="container-fluid py-2  ">
        <div className="terms-condition">
          <Link to="/terms" className="mx-2 text-white">


            Terms & conditions
          </Link>
          <Link to="/privacy" className="mx-2 text-white">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
