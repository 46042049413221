import React, { useState, useEffect } from "react"
import { Button, Input, UncontrolledTooltip } from "reactstrap"
import ReactApexChart from "react-apexcharts"
// 
import { CInputGroup, CInputGroupText } from "@coreui/react"
// import DateRangePicker from "react-bootstrap-daterangepicker"
// import schedule from "../../assets/images/schedule.svg"

import moment from "moment"

const Overview = ({ graphData }) => {
  function getMonthName(monthNumber) {
    const date = new Date()
    date.setMonth(monthNumber - 1)
    return date.toLocaleString("en-US", { month: "short" })
  }
  const [status, setStatus] = useState("")
  const [data, setData] = useState([])
  const [averageData, setAverageData] = useState([])
  useEffect(() => {
    // Process graphData based on selected interval
    if (!graphData || graphData.length === 0) {
      console.log('No graph data available.');
      return;
    }

    const processedData = graphData.map(item => ({
      x: new Date(item.year, item.month - 1, item.day).getTime(),
      y: item.total || 0,
    }));

    console.log('Graph Data:', graphData);
    console.log('Processed Data:', processedData);

    setData(processedData);
  }, [graphData]);

  const series = [
    
    {
      name: "Winner",
      data: data,
      type: "area",
    },
  ]

  const fullDateFormat = date => {
    return (
      <>
        {moment(date).format("MMM DD, YY")}
      </>
    )
  }
  const [options, setOptions] = useState({
    
    chart: {
      height: 350,
      type: "area",
    //   stacked: false,
    //   toolbar: "false",
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth"},
    markers: { size: 0, style: "hollow" },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
        formatter: function (value) {
          return new Date(value).toLocaleDateString("en-GB")
        },
      },
    },

    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    fill: {
      opacity: [0.85, 0.25],
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0.05,
        stops: [42, 100, 100, 100],
      },
    },
  })


  var currDate = new Date()
  currDate.setDate(currDate.getDate() - 30)
  var dateString = currDate.toISOString().split("T")[0]



  return  (
  <React.Fragment>
      <div>
        <div id="overview-chart" className="apex-charts" dir="ltr">
          <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
            <div className="col-lg-2 ">
            </div>
          </div>
          <div id="overview-chart-timeline">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}



export default Overview