import React, { useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from "../../redux/slices/commonSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./Login.scss";
import { post } from '../../utils/api/apiHelper';
function ResetPassword() {
  const [eye, setEye] = useState({ p: false, cp: false });
  const query_params = new URLSearchParams(window.location.search);
  const resetId = query_params.get('resetId');
  console.log(resetId, 'resetId');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      cpassword: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("password is required.")
        .min(6, "password is too short - should be 6 chars minimum.")
        .matches(/[a-zA-Z0-9]/, "password can only contain latin letters."),
      cpassword: Yup.string()
        .required("confirm password is required.")
        .oneOf([Yup.ref('password')], 'Password and confirm password don`t match!')
        .min(6, "confirm password is too short - should be 6 chars minimum.")
        .matches(/[a-zA-Z0-9]/, "confirm password can only contain latin letters."),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const response = await post('/admin/resetPassword', values, resetId);
        dispatch(setLoading(false));
        if (response.status === 200) {
          toast.success(response?.message);
          navigate('/login');
        } else {
          toast.error("Reset session expired!");
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Failed To Reset Password !');
      }
    }
  });
  return (
    <div className='bg-dark-1 d-flex justify-content-center align-items-center vh-100'>

      <div className=" login">

        <div className="edit-profile  rounded-5 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <h2 className='text-white text-center mt-4 forgot'>Reset Password</h2>
              <div className="form-group mb-3 mt-4">
                <label className=' text-white'>Reset Password</label>
                <div className="position-relative">
                  <input placeholder="Reset Password" type={eye.p ? "text" : "password"} name='password' className="form-control font_family"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <span
                    className={`passeye position-absolute`}
                    onClick={() => setEye({ ...eye, p: !eye.p })}
                  >
                    {!eye.p ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-danger">
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>

              <div className="form-group mb-3 mt-4">
                <label className=' text-white'>Confirm Password</label>
                <div className="position-relative">
                  <input placeholder="Confirm Password" type={eye.cp ? "text" : "password"} name='cpassword' className="form-control font_family"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cpassword}
                  />
                  <span
                    className={`passeye position-absolute`}
                    onClick={() => setEye({ ...eye, cp: !eye.cp })}
                  >
                    {!eye.cp ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
                {formik.touched.cpassword && formik.errors.cpassword ? (
                  <p className="text-danger">
                    {formik.errors.cpassword}
                  </p>
                ) : null}
              </div>

              <div className='mt-5'>
                <button type="submit" className="cursor-pointer btn btn-default shadow-0 py-2 w-100" >
                  <span className="text-gradient">Submit</span> </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword