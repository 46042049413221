
import "./MyProfile.scss";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { post, get, put, postAsForm } from "../../utils/api/apiHelper";
import { setLoading } from "../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import { utils } from "ethers";

const MyProfile = () => {
  // let fileId = useId();
  // const [Image, setImage] = React.useState(null);
  // const handleFileSelect = (e) => {
  //   let image = e.target.files[0];
  //   if (image) {
  //     setImage(image);
  //   }
  // };
  const profileFormik = useFormik({
    initialValues: {
      email: '',
      userName: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("please provide valid email address.").required("email is required."),
      userName: Yup.string()
        .required("username is required.")
        .min(6, "username is too short - should be 6 chars minimum.")
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const formData = new FormData();
        formData.append("firstName", values.userName);
        formData.append("email", values.email);
        formData.append('profileImage', Image);
        const response = await postAsForm('/admin/update_profile', formData, localStorage.getItem('accessToken'));
        dispatch(setLoading(false));
        if (response.status === 200) {
          toast.success(response?.message);
        } else {
          toast.error(response?.error ?? "Something went wrong.");
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Something went wrong.');
      }
    }
  });

  const [eye, setEye] = useState({ op: false, np: false, cp: false });
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .required("password is required.")
        .min(6, "password is too short - should be 6 chars minimum.")
        .matches(/[a-zA-Z0-9]/, "password can only contain latin letters."),
      new_password: Yup.string()
        .required("new password is required.")
        .min(6, "new password is too short - should be 6 chars minimum.")
        .matches(/[a-zA-Z0-9]/, "new password can only contain latin letters."),
      confirm_password: Yup.string()
        .required("password is required.")
        .oneOf(
          [Yup.ref("new_password")],
          "New Password and confirm password don`t match!"
        )
    }),
    onSubmit: async ({ old_password, new_password }, { resetForm }) => {
      try {
        dispatch(setLoading(true));
        const response = await post(
          "/admin/changePassword",
          { oldPassword: old_password, newPassword: new_password },
          localStorage.getItem("accessToken")
        );
        dispatch(setLoading(false));

        if (response.status === 200) {
          resetForm()
          toast.success(response?.message);
        } else {
          toast.error(response?.error);
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error("Something went wrong!");
      }
    },
  });

  const walletformik = useFormik({
    initialValues: {
      admin_wallet: ""
    },
    validationSchema: Yup.object({
      admin_wallet: Yup.string().test("isValid", "please enter valid address", value => utils.isAddress(value)).required("wallet address is required."),
    }),
    onSubmit: async ({ admin_wallet }, { resetForm }) => {
      try {
        dispatch(setLoading(true));
        const response = await put(
          "/admin/update/setting",
          { key: 'admin_wallet', value: admin_wallet },
          localStorage.getItem("accessToken")
        );
        dispatch(setLoading(false));

        if (response.status === 200) {
          resetForm();
          toast.success(response?.message);
          getWalletAddress();
        } else {
          toast.error(response?.error);
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error("Something went wrong!");
      }
    },
  });

  const getWalletAddress = async () => {
    try {
      let response = await get(
        `/admin/get_settings?key=admin_wallet`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        walletformik.setFieldValue('admin_wallet', response.data?.[0]?.value)
      } else {
        toast.error("Failed to fetch data!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const getAuth = async () => {
    let token = localStorage.getItem('accessToken');
    let response = await get('/admin/profile', token);
    if (response.status === 200) {
      profileFormik.setFieldValue('email', response.data.email)
      profileFormik.setFieldValue('userName', response.data.firstName)
    }
  }

  useEffect(() => {
    getAuth();
    getWalletAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  return (
    <div className="colorback rounded-4 ">
      <div className="row p-5 ">

        <div className="col-md-3 col-lg-3  ">

          <div className="file mt-2   bg-gadient">
            <div className="file-btn">
              <input type="file" name="file" />
              <i
                className="fa fa-pencil p-1 bg-white rounded-3 text-black"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>



        <div className="col-md-9 col-lg-9 ">
          <form onSubmit={profileFormik.handleSubmit}>
            <div className="mb-2">
              <label htmlFor="" className="text-white mb-2">
                Full Name
              </label>
              <input
                type="text"
                className="form-control  text-white bg-transparent form-control-lg border_gray "
                placeholder=""
                name="userName"
                onChange={profileFormik.handleChange}
                value={profileFormik.values.userName}
              />
            </div>
            <div className="mt-4">
              <label htmlFor="" className="text-white mb-2">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                className="form-control form text-cyan bg-transparent form-control-lg border_gray  "
                placeholder="e-mail"
                onChange={profileFormik.handleChange}
                value={profileFormik.values.email}
              />
            </div>
            <div className="d-inline-block mt-3 ms-auto ">
              <button
                className="btn btn-default update-btn mt-3 py-3 "
                type="submit"
              >
                <span className="text-gradient">Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row p-4">
        <div className="col-md-6">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4   rounded-4">
              <h5 className="text-cyan mt-2 ">Change Password </h5>
              <label htmlFor="" className="text-white mb-2  mt-4 ">
                Enter Old Password
              </label>
              <div className="position-relative">
                <input
                  placeholder="Enter-Old  Password"
                  type={eye.op ? "text" : "password"}
                  name="old_password"
                  className="form-control bg-transparent text-white font_family"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.old_password}

                />
                <span
                  className={`passeye position-absolute`}
                  onClick={() => setEye({ ...eye, op: !eye.op })}
                >
                  {!eye.op ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>
              </div>
              {formik.touched.old_password && formik.errors.old_password ? (
                <p className="text-danger pt-1">
                  {formik.errors.old_password}
                </p>
              ) : null}


              <div className="mt-4">
                <label htmlFor="" className="text-white fs-6 mb-2">
                  New Password
                </label>

                <div className="position-relative">
                  <input
                    placeholder="Enter-New  Password"
                    type={eye.np ? "text" : "password"}
                    name="new_password"
                    className="form-control bg-transparent text-white font_family"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.new_password}
                  />
                  <span
                    className={`passeye position-absolute`}
                    onClick={() => setEye({ ...eye, np: !eye.np })}
                  >
                    {!eye.np ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </span>
                </div>
                {formik.touched.new_password && formik.errors.new_password ? (
                  <p className="text-danger pt-1">
                    {formik.errors.new_password}
                  </p>
                ) : null}

              </div>

              <div className="mt-4 ">
                <label htmlFor="" className="text-white fs-6 mb-2">
                  Confirm Password
                </label>

                <div className="position-relative">
                  <input
                    placeholder="Enter-Confirm Password"
                    type={eye.cp ? "text" : "password"}
                    name="confirm_password"
                    className="form-control bg-transparent text-white font_family"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_password}
                  />
                  <span
                    className={`passeye position-absolute`}
                    onClick={() => setEye({ ...eye, cp: !eye.cp })}
                  >
                    {!eye.cp ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </span>
                </div>
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                  <p className="text-danger pt-1">
                    {formik.errors.confirm_password}
                  </p>
                ) : null}
              </div>

              <div className="d-inline-block mt-3 ms-auto ">
                <button
                  className="btn btn-default update-btn mt-3 py-3 "
                  type="submit"
                >
                  <span className="text-gradient ">Change</span>
                </button>
              </div>
            </div>
          </form>
        </div>



        <div className="col-md-6">
          <div className=" p-4  ">
            <h5 className="text-cyan mt-2 ">Update Wallet Address </h5>
            <form onSubmit={walletformik.handleSubmit}>
              <div className="">
                <label htmlFor="" className="text-white fs-6 mt-4 mb-2">
                  Ethereum Wallet Address
                </label>
                <input
                  type="text"
                  name="admin_wallet"
                  onChange={walletformik.handleChange}
                  value={walletformik.values.admin_wallet}
                  className="form-control  text-white bg-transparent form-control-lg border_gray "
                  placeholder="enter wallet address"
                />
                {walletformik.touched.admin_wallet && walletformik.errors.admin_wallet ? (
                  <p className="text-danger pt-1">
                    {walletformik.errors.admin_wallet}
                  </p>
                ) : null}
              </div>
              <div className="d-inline-block mt-3 ms-auto ">
                <button
                  className="btn btn-default update-btn mt-3 py-3 "
                  type="submit"
                >
                  <span className="text-gradient">Update</span>
                </button>
              </div>
            </form>
          </div>

        </div>

        <div className="col-md-6">

        </div>

        <div className="col-md-6">

        </div>




      </div>






    </div>
  );
};

export default MyProfile;
