
import "./EditSchedule.scss";
import contractAbi from '../../assets/abi/battleAbi.json'
import erc20Abi from '../../assets/abi/erc20Abi.json'
import { useEffect, useState } from "react";
import PaginationComponent from "../Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { get, put } from '../../utils/api/apiHelper';
import { toast } from 'react-toastify';
import { LINK_ADDRESS, fullDateFormat, switchHandler } from '../../utils/common';
import { confirmAlert } from 'react-confirm-alert';
import EditModal from "./EditModal";
import { ethers } from "ethers";


function Editschedule() {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);

  const toggleModalData = (battle) => {
    console.log(battle);
    setItem(battle);
    setModalShow(prev => !prev);
  }

  const cancelHandler = (_id) => {
    const options = {
      title: 'Are you sure to cancel this Battle!',

      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            cancelBattle(_id);
          }
        },
        {
          label: 'No'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "custom-alert-overlay"
    };
    confirmAlert(options);
  };
  const winnerHandler = (battle) => {
    const options = {
      title: battle?.isFulfilled ? 'Declare winner for this Battle!' : 'Request random number for this Battle!',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            declareBattleWinner(battle);
          }
        },
        {
          label: 'No'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "custom-alert-overlay"
    };
    confirmAlert(options);
  };

  const declareBattleWinner = async (battle) => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      await provider.send("eth_requestAccounts", [])
      await switchHandler()
      const connectedAddress = await signer.getAddress();
      if (`${connectedAddress}`.toLowerCase() !== `${battle?.owner}`.toLowerCase()) {
        toast.error("Only Owner Can Declare Winner !");
        return
      }
      console.log(connectedAddress, 'connectedAddress');
      const contractInstance = new ethers.Contract(battle?.contractAddress, contractAbi, signer);
      const lastRequestId = await contractInstance.lastRequestId();
      const RequestStatus = await contractInstance.s_requests(lastRequestId);
      if (RequestStatus?.fulfilled) {
        console.log('in winner');
        const getWinner = await contractInstance.declareWinner({
          gasLimit: 600000
        });
        let confirmation = await getWinner.wait();
        let winnerAmount = await contractInstance.winnerAmount();
        let winner = await contractInstance.winner();
        console.log(confirmation, 'winner confirmation');
        const params = {
          _id: battle?._id, amount_wonned: ethers.utils.formatEther(winnerAmount), winner_address: winner
        };
        await put(
          `/admin/declareWinner`,
          params,
          localStorage.getItem("accessToken")
        ).then((res) => {
          console.log(res);
        });
        toast.success("Winner Declared !");
        await getBattlelist()
      } else {
        console.log('in request');
        const linkContractInstance = new ethers.Contract(LINK_ADDRESS, erc20Abi, signer);
        const contractLinkBalance = await linkContractInstance.balanceOf(battle?.contractAddress);
        if (+ethers.utils.formatEther(contractLinkBalance) < 3) {
          const balance = await linkContractInstance.balanceOf(connectedAddress);
          if (+ethers.utils.formatEther(balance) < 3) {
            toast.error("Your LINK Balance is low !");
            return
            // throw new Error('LINK Balance is low !')
          }
          const trnx = await linkContractInstance.transfer(battle?.contractAddress, ethers.utils.parseEther('3'), {
            gasLimit: 600000
          });
          const receipt = await trnx.wait();
          console.log(receipt, 'Link transfer receipt confirmation');
        }
        const transaction = await contractInstance.requestRandomWords({
          gasLimit: 600000
        });
        let confirmation = await transaction.wait();
        toast.success("Random number generated wait for a while to declare winner !");
        console.log(confirmation, 'random words confirmation');
        await getBattlelist()
      }
    } catch (error) {
      console.error("Something went wrong!", error);
      toast.error("Something went wrong!");
    }
    finally {
      setLoading(false);
    }
  };


  const getBattlelist = async () => {
    try {
      setLoading(true);
      let response = await get(`/admin/battles?pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false`, localStorage.getItem('accessToken'));
      if (response.status === 200) {
        setItems(response.data.items);
        setTotal(response.data.totalItems);
      } else {
        toast.error('Failed to fetch data!');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBattlelist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  const cancelBattle = async (_id) => {
    try {
      const params = {
        status: 'CANCEL',
        _id: _id,
      };
      const response = await put(
        `/admin/cancel_battle`,
        params,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        toast.success("Cancel battle Successfully!");
        getBattlelist()
      } else {
        console.error("Failed to cancel battle");
        toast.error(`${response?.error}`);
      }
    } catch (error) {
      console.error("Something went wrong!", error);
      toast.error("Something went wrong!");
    }
  };

  if (loading) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <div className="custom-loader"></div>
      </div>
    );
  }

  return (
    <>
      <div className=" main-inner">
        <div className="bg-dark-1 mt-4">
          <div className="">
            <div className="row mx-0 py-3 align-items-center">
              <div className=" col-6 col-md-1">
                <p className="text-gray mb-0">battle no</p>
              </div>
              <div className=" col-6 col-md-1 text-center">
                <p className="text-gray mb-0">Name</p>
              </div>
              <div className=" col-6 col-md-1 text-center">
                <p className="text-gray mb-0">Total Price</p>
              </div>
              <div className=" col-6 col-md-1 text-center">
                <p className="text-gray mb-0">prize pool</p>
              </div>
              <div className="col-md-1 text-center  d-md-block">
                <p className="text-gray mb-0">Total fighters</p>
              </div>
              <div className="col-md-2 text-end  d-md-block text-center">
                <p className="text-gray mb-0">battle hash</p>
              </div>
              <div className="col-md-3 text-center d-md-block">
                <p className="text-gray mb-0">start/end Dates</p>
              </div>
              <div className="col-md-2 text-end d-none d-md-block">
                <p className="text-gray mb-0"> <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="bg-transparent border_gray drop-btn border_radius p-2"
                  >
                    <span className="size">{perPage}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="bg-dark-1 border_gray  border_radius">
                    <Dropdown.Item href="" onClick={() => setPerPage(10)} className="text-white font12 p-3 ">
                      <span className="one1">
                        10
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => setPerPage(20)} className="text-white font12 py-2 ">
                      <span className="one1"> 20 </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => setPerPage(50)} className="text-white font12 py-2">
                      <span className="one1"> 50 </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown></p>
              </div>

            </div>
            <div>
              {
                items?.map((data) => {
                  return (
                    <div className="bg-gadient p-space  d-inline-block  w-100 rounded-4 mb-3 clearfix" key={data._id}>
                      <div className="bg-dark-1 rounded-4">
                        <div className="row mx-0 align-items-center py-3 ">
                          <div className=" col-9 col-md-1 mb-3 mb-md-0">
                            <p className="text-white"># {data?.battle_no}</p>
                          </div>
                          <div className="col-3 col-md-1 text-end text-md-center mb-3 mb-md-0">
                            <p className="text-white">{data?.name}</p>
                          </div>
                          <div className="col-3 col-md-1 text-end text-md-center mb-3 mb-md-0">
                            <p className="text-white">{data?.ticket_price}</p>
                          </div>
                          <div className="col-3 col-md-1 text-end text-md-center mb-3 mb-md-0">
                            <p className="text-white">{data?.prize_pool ?? 0}</p>
                          </div>
                          <div className="col-6 d-md-none mb-3 mb-md-0">
                            <p className="text-gray mb-0">Total fighters</p>
                          </div>

                          <div className="col-6 col-md-1 text-end text-md-center mb-3 mb-md-0">
                            <p className="text-gray">{data?.total_tickets_sold ?? 0}</p>
                          </div>
                          <div className="col-6 d-md-none mb-3 mb-md-0">
                            <p className="text-gray mb-0">battle hash</p>
                          </div>
                          <div className="col-md-2 col-6 text-end text-md-center mb-3  mb-md-0">
                            <p className="text-cyan d-inline-block text-break text-truncate cursor-pointer" style={{ width: '100%' }} onClick={() => window.open(`https://sepolia.etherscan.io/address/${data?.contractAddress}`)}>
                              {data?.contractAddress}
                            </p>
                            <p className="text-cyan d-inline-block text-break text-truncate cursor-pointer" style={{ width: '100%' }} onClick={() => window.open(`https://sepolia.etherscan.io/address/${data?.owner}`)}>
                              <span className="text-white">Owner:</span> {data?.owner}
                            </p>
                          </div>
                          <div className=" col-md-3 text-md-end text-md-center pt-md-0 text-break">
                            <p className="start_text mb-0 text-gray text-center">start</p>
                            <p className="text-white mb-0 text-center"> {fullDateFormat(data?.start_date)}</p>
                            <p className="start_text mt-1  mb-0 text-gray text-center">end</p>
                            <p className="text-white mb-0  text-center">{fullDateFormat(data?.end_date)}</p>
                          </div>
                          <div className=" col-md-2 text-md-end text-center pt-5 pt-md-0  ">
                            {
                              !data?.isActive && !data?.isCompleted && data?.status !== 'CANCEL'
                                ?
                                <div className="text-gray ">
                                  <div className=" d-flex flex-wrap">
                                    <div className="mw-100  ms-md-auto  rounded-4 position-relative mb-2">
                                      <div className=" border_gray rounded-4 mt-2">
                                        <button className="btn  py-2  border-0 text-md-center px-2 mb-0 w-100 edit_size "
                                          onClick={() => cancelHandler(data?._id)}
                                        >
                                          <span className="text-white   text-center "
                                          >cancel</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                (
                                  data?.isCompleted && !data?.isResultDeclared
                                    ?
                                    <div className="text-gray text-break text-center">
                                      Declaring Winner
                                    </div>
                                    :
                                    <div className="text-gray text-break text-center">
                                      {data?.status}
                                    </div>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {total > 0 && <PaginationComponent className="mx-auto" itemsCount={total} itemsPerPage={perPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
          </div>
        </div>
        {modalShow && item && <EditModal show={modalShow} onHide={() => toggleModalData(null)} item={item} setLoading={setLoading} getBattlelist={getBattlelist} />}
      </div>
    </>
  );
}

export default Editschedule;
