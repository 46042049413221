import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice';
import battleReducer from './slices/battleSlice';
import commonReducer from './slices/commonSlice';
const reducer = combineReducers({
  user: userReducer,
  battle: battleReducer,
  common: commonReducer
});
export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});