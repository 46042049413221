/* eslint-disable no-unused-vars */
import './History.scss'
import eth from "../../assets/images/ethlogo.svg";
import React from 'react'
import Dropdown from "react-bootstrap/Dropdown";
import { confirmAlert } from 'react-confirm-alert';
import ApproveModal from '../Settings/Modal/ApproveModal';
import { get, put } from "../../utils/api/apiHelper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PaginationComponent from "../Pagination";
import { fullDateFormat } from '../../utils/common';


const Refunds = () => {
    const [item, setItem] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);

    const toggleModalData = (refund) => {
        let data = { ...refund, amount: refund?.amount_refunded }
        setItem(data);
        setModalShow(prev => !prev);
    }

    const getRefunds = async () => {
        try {
            let response = await get(
                `/admin/get_refunds?search=${search}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false`,
                localStorage.getItem("accessToken")
            );
            if (response.status === 200) {
                setItems(response.data.items);
                console.log('=============', items);
                setTotal(response.data.totalItems);
            } else {
                toast.error("Failed to fetch data!");
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
        finally {
            setLoading(false);
        }
    };

    const approveRefund = async (_id, receipt) => {
        try {
            const params = {
                transaction_hash: receipt.hash,
                from_address: receipt.from,
                to_address: receipt.to,
                status: "APPROVED",
                _id: _id
            };
            const response = await put(
                `/admin/update/refund_status`,
                params,
                localStorage.getItem("accessToken")
            );
            if (response.status === 200) {
                toast.success(response.message);
                toggleModalData(null);
                await getRefunds();
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error("Something went wrong!", error);
            throw new Error("Something went wrong!");
        }
    };

    const declineRefund = async (_id, comment = "") => {
        try {
            const params = {
                _id: _id,
                status: "DECLINED"
            };
            setLoading(true)
            const response = await put(
                `/admin/update/refund_status`,
                params,
                localStorage.getItem("accessToken")
            );
            if (response.status === 200) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error("Something went wrong!");
            console.error("Something went wrong!", error);
        }
    };

    const declineHandler = (_id) => {
        const options = {
            title: 'Are you sure to Decline this refund !',

            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        declineRefund(_id).then(getRefunds)
                    }
                },
                {
                    label: 'No'
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "custom-alert-overlay"
        };
        confirmAlert(options);
    };

    useEffect(() => {
        getRefunds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, search]);




    if (loading) {
        return (
            <div className="d-flex vh-100 justify-content-center align-items-center">
                <div className="custom-loader"></div>
            </div>
        );
    }

    return (
        <div className='main-inner'>

            <div className='bg-dark-1 mt-4'>
                <div className="row ">
                    <div className="col-8 col-md-4  d-flex">
                    </div>
                    <div className="col-md-8 col-4  d-flex justify-content-end gap-2">
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className="bg-transparent border_gray drop-btn border_radius p-2"
                            >
                                <span className="size">{perPage}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-dark-1 border_gray  border_radius">
                                <Dropdown.Item href="" onClick={() => setPerPage(10)} className="text-white font12 p-3 ">
                                    <span className="one1">
                                        10
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item href="" onClick={() => setPerPage(20)} className="text-white font12 py-2 ">
                                    <span className="one1"> 20 </span>
                                </Dropdown.Item>
                                <Dropdown.Item href="" onClick={() => setPerPage(50)} className="text-white font12 py-2">
                                    <span className="one1"> 50 </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <div>
                    <div className="battle-enter mt-3">
                        <div className="row mx-0 py-4">
                            <div className=" col-6 col-md-2">
                                <p className="text-gray mb-0">User</p>
                            </div>
                            <div className=" col-6 col-md-2 text-center">
                                <p className="text-gray mb-0">Participants</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0">Total fighters</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0">battle hash</p>
                            </div>
                            <div className="col-md-2 text-end d-none d-md-block">
                                <p className="text-gray mb-0">Date</p>
                            </div>
                            <div className="col-md-2 text-end d-none d-md-block">
                                <p className="text-gray mb-0">Action</p>
                            </div>
                        </div>
                        <div>
                            {items.map((item) => {
                                return (
                                    <div className="bg-gadient p-space d-inline-block  w-100 rounded-4 mb-3 clearfix" key={item?._id}>
                                        <div className="bg-dark-1 rounded-4">
                                            <div className="row mx-0 align-items-center py-3 ">
                                                <div className=" col-9 col-md-2 mb-3 mb-md-0">
                                                    <div className="battle-enter-content">
                                                        <p className="text-white"><span className="text-cyan mb-0">Amount <img src={eth} alt='' /> {item?.amount_refunded?.toFixed(4)}</span></p>
                                                        <p className='text-white'><small>{`${item?.user?.email ?? ""}`}</small></p>
                                                    </div>
                                                </div>
                                                <div className="col-3 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                    <p className="text-white"><span className="text-cyan mb-0">{item?.total_participants}</span></p>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Total fighters</p>
                                                </div>

                                                <div className="col-6 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                    <p className="text-gray">{item?.total_tickets}</p>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">battle hash</p>
                                                </div>
                                                <div className="col-md-2 col-6 text-end text-md-center pt-1 mb-md-0">
                                                    <p className="text-cyan d-inline-block col-6 text-break"><small>{item?.battle?._id}</small></p>
                                                </div>
                                                <div className="col-md-2 text-md-end text-center pt-5 pt-md-0 ">
                                                    <p className="text-gray">{fullDateFormat(item?.createdAt)}</p>
                                                </div>
                                                <div className="col-md-2 text-md-end text-center pt-5 pt-md-0 ">
                                                    {
                                                        item?.status === "PENDING"
                                                            ?
                                                            <div className=" ">
                                                                <div className="btn-load mw-100  ms-md-auto  rounded-5   mb-2">
                                                                    <div className=" bg-dark-1 btn-load-inner border_gray rounded-4 position-relative  ">
                                                                        <button className="btn btn-default border-0 py-1 shadow-0  text-md-center  
                                                                            px-2 bg-dark-1 mb-0 w-100  edit_size cheak p-2 " onClick={() => toggleModalData(item)} disabled={loading}>
                                                                            <span className="text-white   text-center  p-2 ">Approve</span>
                                                                        </button>
                                                                    </div>

                                                                    <div className=" bg-dark-1 btn-load-inner mt-2 border_gray rounded-4 position-relative  ">
                                                                        <button className="btn btn-default border-0 py-1 shadow-0  text-md-center  
                                                                                px-2 bg-dark-1 mb-0 w-100  edit_size cheak p-2 " onClick={() => declineHandler(item?._id)} disabled={loading}>
                                                                            <span className="text-white   text-center  p-2 ">
                                                                                Decline
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <p className="text-cyan text-center d-inline-block mb-0 me-3">{item?.status}</p>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                            <div className="pb-4 mt-4 ">
                                {total > 0 && (
                                    <PaginationComponent
                                        className="mx-auto"
                                        itemsCount={total}
                                        itemsPerPage={perPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </div>



                    </div>
                </div>


            </div>
            <ApproveModal show={modalShow} onHide={() => toggleModalData(null)} item={item} saveTrx={approveRefund} setLoading={setLoading} />
        </div>
    )
}


export default Refunds;