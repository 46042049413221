import React from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from "../../redux/slices/commonSlice";
import "./Login.scss";
import { post } from '../../utils/api/apiHelper';
import logo from '../../assets/images/adminlogo.svg'
function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("plz provide valid email address.").required("email is required."),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const response = await post('/admin/forgot-password', { ...values, url: 'https://admin.chainwarz.com/' });
        dispatch(setLoading(false));
        if (response.status === 200) {
          toast.success(response?.message);
          navigate('/login');
        } else {
          toast.error(response?.error ?? "Something went wrong.");
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Something went wrong.!');
      }
    }
  });
  return (
    <div className='bg-dark-1 d-flex justify-content-center align-items-center vh-100'>

      <div className=" login">

        <div className="edit-profile  rounded-5 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className='text-center'>
                <img src={logo} alt="" width={80} className='py-2 ' />
              </div>

              <h3 className='text-white text-center mt-4 forgot'>Forgot Password</h3>
              <div className="form-group mb-3 mt-4">
                <label className=' text-white'>Email</label>
                <input placeholder="Enter Email" type="email" name='email' className="form-control font_family"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-danger">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>




              <div className='mt-5'>
                <button type="submit" className="cursor-pointer btn btn-default shadow-0 py-2 w-100" >
                  <span className="text-gradient">Submit</span> </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword