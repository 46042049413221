import React, { useEffect, useState } from 'react';
import './Pagination.scss';
import left from "../../assets/images/Left.svg";
import right from "../../assets/images/Right.svg";

const PaginationComponent = ({
    itemsCount,
    itemsPerPage,
    currentPage,
    setCurrentPage,
}) => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    const maxVisiblePages = 5;

    const [visiblePages, setVisiblePages] = useState([]);

    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;

    const changePage = number => {
        if (currentPage === number) return;
        setCurrentPage(number);
    };

    const onPageNumberClick = pageNumber => {
        changePage(pageNumber);
    };

    const onPreviousPageClick = () => {
        changePage(currentPage => currentPage - 1);
    };

    const onNextPageClick = () => {
        changePage(currentPage => currentPage + 1);
    };

    const setLastPageAsCurrent = () => {
        if (currentPage > 1 && currentPage > pagesCount) {
            setCurrentPage(pagesCount);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(setLastPageAsCurrent, [pagesCount]);

    useEffect(() => {
        const calculateVisiblePages = () => {
            const start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            const end = Math.min(pagesCount, start + maxVisiblePages - 1);
            console.log(start,end,pagesCount,currentPage,'numbers');
            setVisiblePages([...Array(end - start + 1)].map((_, index) => start + index));
        };

        calculateVisiblePages();
    }, [currentPage, pagesCount]);

    const pageNumbers = visiblePages.map(pageNumber => (
        <li
            className={`page-item ${pageNumber === currentPage ? 'active ' : ''}`}
            key={pageNumber}
            onClick={() => onPageNumberClick(pageNumber)}
        >
            <div className="btn-load-inner">
                <button
                    className={`page-item ${pageNumber === currentPage ? 'bg-gadient ' : ''} text-white page-link  bg-dark-1 pagi text-center  border_gray rounded-3 `}
                >
                    {pageNumber}
                </button>
            </div>
        </li>
    ));

    return (
        <div className="pb-4 mt-4 ">
            <ul className="list-unstyled d-flex justify-content-center mx-auto text-white hei-widt">
                <li className="border_gray page-item rounded-3 text-white">
                    <button
                        onClick={onPreviousPageClick}
                        disabled={isCurrentPageFirst}
                        className="btn border-0"
                    >
                        <img src={left} alt="" className="" />
                    </button>
                </li>
                {pageNumbers.length > 0 && currentPage > Math.floor(maxVisiblePages / 2) && (
                    <li className="border_gray rounded-3 page-item disabled">
                        <button className="btn border-0" disabled>
                            <span>...</span>
                        </button>
                    </li>
                )}
                {pageNumbers}
                {pageNumbers.length > 0 && currentPage <= pagesCount - Math.floor(maxVisiblePages / 2) && (
                    <li className="border_gray rounded-3 page-item disabled">
                        <button className="btn border-0" disabled>
                            <span>...</span>
                        </button>
                    </li>
                )}
                <li className="border_gray rounded-3 page-item">
                    <button
                        className="btn border-0"
                        onClick={onNextPageClick}
                        disabled={isCurrentPageLast}
                    >
                        <img src={right} alt="" />
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default PaginationComponent;
