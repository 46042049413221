import logo from "../../assets/images/chainwarzlogo.png";
import Navbar from "react-bootstrap/Navbar";
import "./Header.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/slices/userSlice";
import Dropdown from "react-bootstrap/Dropdown";
import user from "../../assets/images/user.svg";
import { LuLogOut } from "react-icons/lu";
import { FaUserTie } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myProfile = ()=>{
    navigate("/my-profile");
  }
  const logOutHandler = () => {
    const options = {
      title: 'Are you sure you want to logout?',
     
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(logOut());
            localStorage.clear();
            navigate("/login");
            toast.success("Logout successfully!");
          }
        },
        {
          label: 'No'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName:"custom-alert-overlay"
    };
    confirmAlert(options);
  };

  
  const handleToggle = (e) => {
    e.preventDefault()
    document.body.classList.toggle('body-element')
  }
  return (
    <div className=" bg-dark-1">
      <div className="  text-white ">
        <header className="header-element   ">
          <Navbar expand="lg" className="py-0 ">
            <div className="d-flex me-auto align-items-center">


              <Navbar.Brand className="" href="/">
                <img src={logo} alt="" />

              </Navbar.Brand>
              <button
                type="button"
                onClick={handleToggle}
                className=" bg-dark-1 rounded-2 border_gray  d-sm-block hide-btn text-white"
              >
                <AiOutlineArrowLeft />

              </button>
            </div>
            {/* <div className={` ${show ? "customcollapse" : ""}`}></div> */}

            {/* <button className='text-white btn-logout btn-gadient' onClick={logOutHandler}>Log Out</button> */}

            <Dropdown align="end" className="">
              <Dropdown.Toggle
                className="rounded p-2 bg-gadient   rounded-4 "
                id="dropdown-basic-end"
              >
                <img src={user} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded">
                <Dropdown.Item href="" onClick={myProfile}>
                  <FaUserTie /> <span className="p-1"> Profile</span>
                </Dropdown.Item>

                <Dropdown.Item href="" onClick={logOutHandler}>
                  <LuLogOut />
                  <span className="p-1">logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar>
        </header>
      </div>
    </div>
  );
};

export default Header;
