import "./Manual.scss";
import React, { forwardRef, useId } from "react";
import user from "../../assets/images/user.svg";
import eth from "../../assets/images/ethlogo.svg";
import bag from "../../assets/images/Handbag.svg";
import { IoInfinite } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import pic from "../../assets/images/PictureInPicture.svg";
import del from "../../assets/images/Trash.svg";
import photo from "../../assets/images/basephoto.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ImCross } from "react-icons/im";
import { ethers } from "ethers";
import CreateBattleContractAbi from "../../assets/abi/battleAbi.json";
import ChainWarzByteCode from "../../assets/abi/byteCode.json";
import moment from "moment";
import { LINK_ADDRESS } from '../../utils/common';
import erc20Abi from '../../assets/abi/erc20Abi.json';
import contractAbi from '../../assets/abi/battleAbi.json';

import {
  setRequirements,
  setName,
  setTicketPrice,
  addBundle,
  removeBundle,
  updateBundle,
  refresh,
  setImage,
  setEndDate,
  setStartDate,
} from "../../redux/slices/battleSlice";
import { postAsForm } from "../../utils/api/apiHelper";
import { setLoading } from "../../redux/slices/commonSlice";
import ReactDatePicker from "react-datepicker";
import Web3 from "web3";
import "react-datepicker/dist/react-datepicker.css";
import { switchHandler } from "../../utils/common";

const DateTimeInput = ({ value, onChange, className }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={className} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <ReactDatePicker
      showTimeSelect
      dateFormat="dd-MM-yyyy hh:mm:ss aa"
      selected={value}
      minDate={new Date()}
      onChange={onChange}
      customInput={<ExampleCustomInput />}
    />
  );
};
const Manual = () => {
  let fileId = useId();
  const dispatch = useDispatch();
  const Battle = useSelector((state) => state.battle);
  const toggleBattleRequirements = (type) => () => {
    let checked = Battle.requirements[type];
    let requirements = { ...Battle?.requirements, [type]: !checked };
    dispatch(setRequirements({ requirements }));
  };
  const handleFileSelect = (e) => {
    let image = e.target.files[0];
    if (image) {
      dispatch(setImage({ image }));
    }
  };

  const createBattle = async () => {
    try {
      if (!window.ethereum) {
        toast.error("Please Install Metamask !");
        return;
      }
      dispatch(setLoading(true));
      if (
        !Object.keys(Battle.requirements).some(
          (key) => Battle?.requirements?.[key]
        )
      ) {
        toast.error("Please select atleast one requirement!");
        return;
      }
      if (Battle.name === "") {
        toast.error("Please enter the name !");
        return;
      }
      if (Number(Battle?.ticket_price) <= 0) {
        toast.error("Ticket Price must be greater than 0 !");
        return;
      }
      if (Battle.start_date === "") {
        toast.error("Please select battle start date !");
        return;
      }
      if (Battle.end_date === "") {
        toast.error("Please select battle end date !");
        return;
      }
      if (new Date(Battle.start_date) >= new Date(Battle.end_date)) {
        toast.error("End date must be greater than start date !");
        return;
      }
      const bundles = Battle.bundles.filter((b) => b.price && b.entries); // Filter out empty bundles
      const bundleKeys = bundles.map((b) => b.entries);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const web3 = new Web3(Web3.givenProvider);
      const bundleValues = bundles.map((b) =>
        web3.utils.toWei(b.price, 'ether')
      );
      const startDate = Math.round(
        new Date(Battle.start_date).getTime() / 1000
      );

      const endDate = Math.round(new Date(Battle.end_date).getTime() / 1000);
      await provider.send("eth_requestAccounts", []).then(async (res) => {
        await switchHandler();
        const signer = provider.getSigner();
        const connectedAddress = await signer.getAddress();
        const linkContractInstance = new ethers.Contract(LINK_ADDRESS, erc20Abi, signer);
        const balance = await linkContractInstance.balanceOf(connectedAddress);
        if (+ethers.utils.formatEther(balance) < 10) {
          toast.error("Your LINK Balance is low !");
          throw new Error('LINK Balance is low !')
        }

        let factory = new ethers.ContractFactory(
          CreateBattleContractAbi,
          ChainWarzByteCode.object,
          signer
        );
        console.log('5 >>>>>>');

        let contract = await factory.deploy(
          Battle.name,
          startDate, // Convert date to Unix timestamp
          endDate, // Convert date to Unix timestamp
          web3.utils.toWei(Battle.ticket_price, "ether"),
          Battle.image.name, // This should be the image URL or IPFS hash, as per your requirements
          bundleKeys,
          bundleValues
        );
        console.log('6 >>>>>>');

        let finalDeploy = await contract.deployTransaction.wait();

        console.log('7 >>>>>>');
        let FinalcontractAddress = finalDeploy.contractAddress;
        console.log(FinalcontractAddress, "contractAddress");

        if (FinalcontractAddress) {

          console.log(Battle.start_date, "<<-- Battle.start_date")
          console.log(moment(Battle.start_date).format("YYYY-MM-DD HH:mm"))

          console.log(Battle.end_date, "<<-- Battle.end_date")
          console.log(moment(Battle.end_date).format("YYYY-MM-DD HH:mm"))

          const formData = new FormData();
          formData.append("name", Battle.name);
          formData.append("type", Battle.type);
          formData.append("image", Battle.image);
          // formData.append("start_date", Battle.start_date);
          formData.append("start_date", moment(Battle.start_date).format("YYYY-MM-DD HH:mm"));
          // formData.append("end_date", Battle.end_date);
          formData.append("end_date", moment(Battle.end_date).format("YYYY-MM-DD HH:mm"));

          formData.append("bundles", JSON.stringify(Battle.bundles));
          formData.append("ticket_price", Battle.ticket_price);
          formData.append("requirements", JSON.stringify(Battle.requirements));
          formData.append("contractAddress", FinalcontractAddress);

          const response = await postAsForm("/admin/createBattle", formData, localStorage.getItem("accessToken"));

          if (response.status === 200) {
            dispatch(refresh());
            toast.success("Battle created successfully!");
          } else {
            toast.error(response?.error ?? "Something went wrong!!.");
          }
          const trnx = await linkContractInstance.transfer(FinalcontractAddress, ethers.utils.parseEther('10'), {
            gasLimit: 600000
          });
          const receipt = await trnx.wait();
          toast.success("LINK transfered successfully!");
          console.log(receipt, 'Link transfer receipt !');
          const contractInstance = new ethers.Contract(FinalcontractAddress, contractAbi, signer);

          const registerUpkeepTrnx = await contractInstance.registerUpkeep('test', ethers.utils.parseEther('6'), '500000', {
            gasLimit: 600000
          });
          const confirmationTx = await registerUpkeepTrnx.wait();
          dispatch(setLoading(false));
          toast.success("Automatic key registered successfully !");
          console.log(confirmationTx, 'Automatic key receipt !');
        } else {
          dispatch(setLoading(false));
          toast.error("Something went wrong!!");
        }
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
      toast.error("Failed create Battle !");
    }
  };

  return (
    <div className="  colorback pb-2 rounded-4 mt-4">
      <div className="row m-0 pb-0 ">
        <div className="col-lg-5 p-4">
          <h1 className="text-white">Manual</h1>
        </div>
        <div className=" col-lg-6 social ">
          <h5 className="text-white req text-uppercase mt-3">Requirement</h5>
          <ul className="list-unstyled d-flex flex-md-wrap flex-wrap  justify-content-between ">
            <li>
              <div className="form-check py-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={Battle.requirements.email}
                  onClick={toggleBattleRequirements("email")}
                  onChange={() => { }}
                  id="Check1"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check1">
                  Email
                </label>
              </div>
            </li>
            {/* <li>
              <div className="form-check  py-2 ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={Battle.requirements.twitter}
                  onClick={toggleBattleRequirements('twitter')}
                  onChange={() => { }}
                  id="Check2"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check2">
                  Twitter
                </label>
              </div>
            </li>
            <li>
              <div className="form-check   py-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={Battle.requirements.discord}
                  onClick={toggleBattleRequirements('discord')}
                  onChange={() => { }}
                  id="Check3"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check3">
                  Discord
                </label>
              </div>
            </li> */}
          </ul>
        </div>

        <hr className="text-gray" />
      </div>
      <div className="p-4">
        <div className="form-row d-md-flex gap-3 text-white  ">
          <div className=" col-md-6 col-lg-3 labelsize  mb-4">
            <label className="pb-1" htmlFor="start_date">
              Battle Start Date
            </label>
            <DateTimeInput
              name="start_date"
              className="form-control p-0 date_text text-white  bg-transparent  border_gray"
              value={Battle.start_date}
              onChange={(date) => {
                dispatch(setStartDate({ start_date: date }));
              }}
            />
          </div>
          <div className=" col-md-6 col-lg-3 labelsize mb-4">
            <label className="pb-1" htmlFor="end_date">
              Battle End Date
            </label>
            <DateTimeInput
              name="end_date"
              className="form-control p-0 text-white date_text bg-transparent  border_gray"
              value={Battle.end_date}
              onChange={(date) => {
                dispatch(setEndDate({ end_date: date }));
              }}
            />
          </div>
        </div>
        <div className="form-row d-md-flex gap-3 text-white  ">
          <div className="col-md-6 col-lg-3  labelsize position-relative mb-4  ">
            <img src={user} alt="" width={20} className=" user" />
            <label className="pb-1" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="form-control  text-white  bg-transparent form-control-lg border_gray"
              placeholder="name"
              value={Battle.name}
              onChange={(e) => {
                dispatch(setName({ name: e.target.value }));
              }}
            />
          </div>
          <div className=" col-md-6 col-lg-3 labelsize position-relative mb-4">
            <img src={eth} alt="" width={12} className="user" />
            <label className="pb-1">Ticket price</label>
            <input
              type="text"
              className="form-control form text-white bg-transparent form-control-lg border_gray "
              placeholder="price"
              value={Battle.ticket_price}
              onChange={(e) => {
                if (!Number.isNaN(Number(e.target.value))) {
                  dispatch(setTicketPrice({ ticket_price: e.target.value }));
                }
              }}
            />
          </div>
        </div>
        {Battle.bundles.map((item, index) => (
          <div className="row mt-4 " key={index}>
            <p className="text-white ">Bundle {index + 1}</p>
            <div className="col-12 col-lg-3 mb-4 labelsize position-relative text-white">
              <img src={bag} alt="" width={15} className="bag" />
              <label className="pb-1">Price</label>
              <input
                type="text"
                value={item.price}
                className="form-control text-white bg-transparent form-control-lg border_gray "
                placeholder="price"
                onChange={(e) => {
                  if (!Number.isNaN(Number(e.target.value))) {
                    dispatch(
                      updateBundle({ index, bundle: { price: e.target.value } })
                    );
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-3  mb-4 labelsize position-relative text-white">
              <IoInfinite className="infi" />
              <label className="pb-1">Entries</label>
              <input
                type="text"
                value={item.entries}
                className="form-control text-white bg-transparent form-control-lg border_gray "
                placeholder="entries"
                onChange={(e) => {
                  if (!Number.isNaN(Number(e.target.value))) {
                    dispatch(
                      updateBundle({
                        index,
                        bundle: { entries: e.target.value },
                      })
                    );
                  }
                }}
              />
            </div>
            {index > 0 && (
              <div className=" col-12 col-md-3 mt-4">
                <div className=" ">
                  <button
                    className="rounded-2 delete border_gray color-del "
                    onClick={() => dispatch(removeBundle({ index }))}
                  >
                    <img src={del} alt="" width={24} className="deleteimg  " />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        {Battle?.bundles?.length < 5 && (
          <div className="mt-4">
            <button
              className="btn rounded-3 colorskyblue add-bundle"
              onClick={() => dispatch(addBundle())}
            >
              <span className="text-white add ">
                <FiPlus />
              </span>
            </button>
            <span className="text-white p-2 labelsize">Add Bundle</span>
          </div>
        )}

        <div className="row ">
          <div className="col-md-3 figure_width mt-4 d-inline-block">
            <p className="text-white">Battle Image</p>

            <input
              type="file"
              accept="image/*"
              onChange={handleFileSelect}
              id={fileId}
              hidden={true}
            />
            <figure className="upload-figure  img-background rounded-4 position-relative">
              {Battle.image ? (
                <React.Fragment>
                  <div className="remove">
                    <ImCross
                      className=""
                      onClick={() => dispatch(setImage({ image: null }))}
                    />
                  </div>
                  <label htmlFor={fileId} className="overflow-hidden h-100">
                    <img
                      src={URL.createObjectURL(Battle.image)}
                      alt=""
                      className="image"
                    />
                  </label>
                </React.Fragment>
              ) : (
                <label htmlFor={fileId}>
                  <img src={photo} alt="" className="photo" />
                </label>
              )}
            </figure>

            <label
              className="btn border_gray btn-upload mt-4 d-block"
              htmlFor={fileId}
            >
              <span className="btn-file text-white">
                <span>
                  <img src={pic} alt="" />
                </span>
                Upload Image
              </span>
            </label>
          </div>

          <div className="d-inline-block mt-3 ">
            <button
              className="btn btn-default new-btn mt-3 btn-width"
              onClick={createBattle}
            >
              <span className="text-gradient">Create </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manual;