import { createSlice } from '@reduxjs/toolkit';

const battleSlice = createSlice({
    name: 'battle',
    initialState: {
        name: '',
        type: 'manual',
        image: '',
        bundles: [
            { price: '', entries: '' },
            { price: '', entries: '' },
            { price: '', entries: '' },
            { price: '', entries: '' },
            { price: '', entries: '' }
        ],
        ticket_price: '',
        start_date: new Date(),
        end_date: new Date(Date.now() +  86400000), // 24 hours added
        requirements: {
            email: true,
            twitter: false,
            discord: false
        },
    },
    reducers: {
        setName: (state, action) => {
            state.name = action.payload.name;
        },
        setStartDate: (state, action) => {
            state.start_date = action.payload.start_date;
        },
        setEndDate: (state, action) => {
            state.end_date = action.payload.end_date;
        },
        setType: (state, action) => {
            state.type = action.payload.type;
        },
        setTicketPrice: (state, action) => {
            state.ticket_price = action.payload.ticket_price;
        },
        setImage: (state, action) => {
            state.image = action.payload?.image;
        },
        setRequirements: (state, action) => {
            state.requirements = action.payload?.requirements;
        },
        addBundle: (state, action) => {
            state.bundles = [...state.bundles, { price: '', entries: '' }];
        },
        updateBundle: (state, action) => {
            state.bundles = state.bundles.map((_bundle, index) => index === action.payload?.index ? { ..._bundle, ...action.payload?.bundle } : _bundle);
        },
        removeBundle: (state, action) => {
            state.bundles = state.bundles.filter((_, index) => index !== action.payload?.index);
        },
        refresh: (state, action) => {
            state.name = '';
            state.image = '';
            state.bundles = [
                { price: '', entries: '' },
                { price: '', entries: '' },
                { price: '', entries: '' },
                { price: '', entries: '' },
                { price: '', entries: '' }
            ];
            state.end_date = new Date(Date.now() +  86400000);
            state.start_date = new Date();
            state.ticket_price = '';
            state.requirements = {
                email: true,
                twitter: false,
                discord: false
            };
        }
    },
})

export const { refresh, removeBundle, addBundle, setImage, setRequirements, setTicketPrice, setName, setType, updateBundle, setEndDate, setStartDate } = battleSlice.actions

export default battleSlice.reducer;