import React, { useEffect, useState } from 'react'
import Dropdown from "react-bootstrap/Dropdown";

import "./WinnerVerification.scss"
// import thumbsup from "../../assets/images/ThumbsUp.svg"
// import thumbsdown from "../../assets/images/ThumbsDown.svg"
import magnifyglass from "../../assets/images/MagnifyingGlass.svg";
import download from "../../assets/images/DownloadSimple.svg";
import { get, put } from '../../utils/api/apiHelper';
import Popup from '../Verificationpopup/Verificationpopup';
import PaginationComponent from '../Pagination';
import { toast } from 'react-toastify';
import { downloadCSV, fullDateFormat,showSlicedText } from '../../utils/common';

function WinnerVerification() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [items, setItems] = useState([]);
  const [id, setId] = useState(null);
  const [total, setTotal] = useState(0);
  const [redo, toggleRedo] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [search, setSearch] = useState("");

  const toggleModalData = (data) => {
    setId(data?._id ?? null);
    setAnswers(data?.answers ?? []);
    setModalShow(prev => !prev);
  }
  const refresh = () => toggleRedo(prev => !prev);

  const downloadAllowlistCsv = async () => {
    try {
      let response = await get(`/admin/allowlists?search=${search}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=true`, localStorage.getItem('accessToken'));
      if (response.status === 200) {
        downloadCSV(response?.data, 'allowlist');
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  }
  
  const updateAllowlistStatus = async (status) => {
    try {
      let response = await put(`/admin/allowlist`, { _id: id, status }, localStorage.getItem('accessToken'));
      if (response.status === 200) {
        refresh();
        toggleModalData();
        toast.success(response?.message);
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  }

  useEffect(() => {
    const getAllowlist = async () => {
      try {
        let response = await get(`/admin/allowlists?search=${search}&pageNumber=${currentPage}&pageSize=${perPage}&exportCSV=false`, localStorage.getItem('accessToken'));
        if (response.status === 200) {
          setItems(response.data.items);
          setTotal(response.data.totalItems);
        } else {
          toast.error('Failed to fetch data!');
        }
      } catch (error) {
        toast.error('Something went wrong!');
      }
    }
    getAllowlist();
  }, [currentPage, perPage, search, redo]);

  return (
    <div className='main-inner'>
      <div className="bg-dark-1 mt-4 ">
        <div className="row ">
          <div className="col-8 col-md-4  d-flex">
            <div className=" d-flex">
              <div className=" position-relative w-100">
                <input
                  type="text"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  className="bg-transparent border-0 w-100 py-2  ps-5 text-white"
                  placeholder="searchbar"
                />
                <div className="search-icon">
                  <img src={magnifyglass} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-4  d-flex justify-content-end gap-2">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="bg-transparent border_gray drop-btn border_radius p-2"
              >
                <span className="size">{perPage}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="bg-dark-1 border_gray  border_radius">
                <Dropdown.Item href="" onClick={() => setPerPage(10)} className="text-white font12 p-3 ">
                  <span className="one1">
                    10
                  </span>
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={() => setPerPage(20)} className="text-white font12 py-2 ">
                  <span className="one1"> 20 </span>
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={() => setPerPage(50)} className="text-white font12 py-2">
                  <span className="one1"> 50 </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button className="bg-transparent border_gray text-white border_radius p-2  mb-2" onClick={downloadAllowlistCsv}>
              <img src={download} alt="" className=" " /> <span className="">csv</span>
            </button>
          </div>
        </div>
        <div className="battle-enter mt-3">
          <div className="row mx-0 py-4">
            <div className=" col-6 col-md-2">
              <p className="text-gray mb-0">user name</p>
            </div>
            <div className=" col-6 col-md-2 text-start">
              <p className="text-gray mb-0">submission date</p>
            </div>
            <div className="col-md-3 text-center d-none d-md-block">
              <p className="text-gray mb-0">user email</p>
            </div>
            <div className="col-md-3 text-center d-none d-md-block">
              <p className="text-gray mb-0">wallet address</p>
            </div>
            <div className="col-md-2 text-start d-none d-md-block">
              <p className="text-gray mb-0"></p>
            </div>
          </div>
          {
            items?.map((item) => {
              return (
                <div>
                  <div className="bg-gadient p-space d-inline-block  w-100 rounded-4 mb-3 clearfix">
                    <div className="bg-dark-1 rounded-4">
                      <div className="row mx-0 align-items-center py-3 ">
                        <div className=" col-9 col-md-2 mb-3 mb-md-0">

                          <p className='text-white mb-0'>{item?.user?.userName}</p>

                        </div>
                        <div className="col-3 col-md-2 text-md-start mb-3 mb-md-0">
                          <p className="text-white mb-0 text-truncate">{fullDateFormat(item.createdAt)}</p>

                        </div>
                        <div className="col-6 d-md-none mb-3 mb-md-0">
                          <p className="text-gray mb-0">user email</p>
                        </div>

                        <div className="col-6 col-md-3 text-end text-md-center mb-3 mb-md-0">
                          <p className="text-cyan text-truncate mb-0">{item?.user?.email}</p>
                        </div>
                        <div className="col-6 d-md-none mb-3 mb-md-0">
                          <p className="text-gray mb-0"> wallet address</p>
                        </div>
                        <div className="col-md-3 col-6 text-end text-md-center mb-3 mb-md-0">
                          <p className="text-white d-inline-block text-elips mb-0">{showSlicedText(item?.walletAddress)}</p>
                        </div>
                        <div className="col-md-2 text-md-end text-center pt-5 pt-md-0 ">

                          <div className=" ">
                            <div className="btn-load mw-100  ms-md-auto  rounded-5   mb-2">
                              <div className=" bg-dark-1 btn-load-inner border_gray rounded-4 position-relative  ">
                                {
                                  item["status"] === "PENDING" ?
                                    <button className="btn btn-default border-0 py-2 shadow-0  text-md-center  
                                        px-2 bg-dark-1 mb-0 w-100  edit_size cheak p-2 " onClick={() => toggleModalData(item)}>
                                      <span className="text-white   text-center  p-2 ">check</span>
                                    </button>
                                    :
                                    <button className="btn btn-default border-0 py-2 shadow-0  text-md-center  
                                        px-2 bg-dark-1 mb-0 w-100  edit_size cheak p-2 ">
                                      <span className="text-white   text-center  p-2 ">{item["status"]}</span>
                                    </button>
                                }

                              </div>
                            </div>
                            <div />
                            {/* <div className=" mw-100  ms-auto ms-2  mb-2">
                            <div className="    position-relative   ">
                              <button className="  btn  text-md-center border_like border rounded-4 px-2 bg-dark-1   w-100 ">
                                <span className=" p-1  text-center text-white"><img src={thumbsup } alt="" /></span>
                              </button>
                            </div>
                          </div> */}
                            {/* <div className="mw-100  ms-auto ms-2   rounded-5  mb-2 ">
                            <div className=" bg-dark-1 position-relative  ">
                              <button  className=" btn border_like border rounded-4 text-md-center px-2 bg-dark-1 mb-0 w-100 ">
                                <span className="p-1 text-center text-white text-center"><img src={thumbsdown} alt="" /></span>
                              </button>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {total > 0 && <PaginationComponent className="mx-auto" itemsCount={total} itemsPerPage={perPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      </div>
      <Popup show={modalShow} onHide={toggleModalData} answers={answers} updateStatus={updateAllowlistStatus} />
    </div>
  )
}

export default WinnerVerification;