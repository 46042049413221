import React from 'react'
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Manual from './Manual';
import Automatic from './Automatic';
import './CreateBattle.scss';
import { useDispatch } from "react-redux";
import { setType, refresh } from "../../redux/slices/battleSlice";

const CreateBattle = () => {
  const dispatch = useDispatch();
  const changeBattleType = (type) => {
    dispatch(refresh());
    dispatch(setType({ type }));
  };
  return (
    <div className='main-inner'>

   
    <div className='mt-4 '>
      <Tab.Container id="left-tabs-example" defaultActiveKey="manual" className="bg-transparent " onSelect={changeBattleType}>
        <div className="row text-md-start text-center m-1">
          <Nav variant="pills col-6 col-md-12" className="b">
            <Nav.Item>
              <Nav.Link eventKey="manual" className="upcome">Manual</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="automatic">Automatic</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content className='tabs'>
          <Tab.Pane eventKey="manual"> <Manual />  </Tab.Pane>
          <Tab.Pane eventKey="automatic"><Automatic /></Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
       
    </div>
  )
}

export default CreateBattle