import "./Main.scss";
import square from "../../assets/images/squaresfour.svg";
import sword from "../../assets/images/sword.svg";
import list from "../../assets/images/listnumbers.svg";
import pencil from "../../assets/images/pencil.svg";
import user from "../../assets/images/users.svg";
import { NavLink } from "react-router-dom";
import { RiRefund2Line } from "react-icons/ri";


const Sidebar = ({ children, props }) => {

  return (
    <div className="bg-dark-1 pt-head ">
      {/* <div className={` ${show ? 'customcollapse' : ""}`} > */}
      {/* <button onClick={() => setShow(!show)} className=" bg-dark-1 rounded-2 border_gray  d-sm-block hide-btn">
          <span className="navbar-toggler-icon text-white h5"><AiOutlineMenu className="mt-2" /></span>
        </button> */}
      <div className="container-fluid  sidebar-space ">

        <div className='position-fixed sidebar-area ' >



          <ul className="  ull list-unstyled  mt-1">
            <li className="btn-dash  bg-gadient active rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={square} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">Dashboard</span>
                </NavLink>
              </div>
            </li>

            <li className="btn-dash bg-gadient active mt-2 rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/create-battle"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={sword} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">Create Battle</span>
                </NavLink>
              </div>
            </li>

            <li className="btn-dash mt-2 bg-gadient active  rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/winners"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={list} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">Winners</span>
                </NavLink>
              </div>
            </li>
            {/* <li className="btn-dash mt-2 bg-gadient active  rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/refunds"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit mx-auto">
                    <RiRefund2Line fontSize={35} color="white" style={{ maxWidth: "100%" }} />
                  </span>
                  <span className="text-white fontsize p-2">Refunds</span>
                </NavLink>
              </div>
            </li> */}
            <li className="btn-dash active bg-gadient mt-2 rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/scheduled-battles"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={pencil} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">
                    Scheduled battles
                  </span>
                </NavLink>
              </div>
            </li>

            <li className="btn-dash  mt-2 bg-gadient active rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/users"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={user} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">
                    User Management
                  </span>
                </NavLink>
              </div>
            </li>

            <li className="btn-dash  mt-2 bg-gadient active  rounded-4">
              <div className=" btn-load-inner position-relative">
                <NavLink
                  to="/allowlist"
                  className=" btn btn-default   btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start"
                >
                  <span className=" rounded-2 img-edit">
                    <img src={user} alt="" width={35} />
                  </span>

                  <span className="text-white fontsize p-2">Allowlist</span>
                </NavLink>
              </div>
            </li>





            {/* <li className="btn-dash  mt-2 bg-gadient active  rounded-4 nav-item  dropdown"> */}
            {/* <div className=" btn-load-inner dropdown-toggle "  id="navbarDropdownw" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                
                  <div className="  " >
                    <span className=" rounded-2 img-edit p-2">
                      <img src={eth} alt="" width={20} />
                    </span>

                    <span className="text-white fontsize p-2">Transaction</span>
                  </div>
                </div> */}
            {/* <Dropdown className="position-relative nav-dropbtn">
                <Dropdown.Toggle variant="" className="btn btn-default text-white  fontsize  d-flex btn-dashboard shadow-0 bg-dark-1 mb-0 w-100 text-start" id="dropdown-basic">
                  <div className=" btn-load-inner d-flex">
                    <span className=" rounded-2 img-edit p-2">
                      <img src={eth} alt="" width={20} />
                    </span>
                    <span className="d-none d-sm-block p-2">Transaction</span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="nav-dropdown bg-transparent ">
                  <Dropdown.Item className="text-white nav-dropdown-link"><Link to={'/withdraw'} className="text-white p-5 text-center ps-0">Withdraw</Link></Dropdown.Item>
                  <Dropdown.Item className="text-white nav-dropdown-link"><Link to={'/deposits'} className="text-white p-5 text-center ps-0">Deposit</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            {/* </li> */}
          </ul>

        </div>



        <main className="pe-md-4 position-relative">
          <div className="main-inner">
            {children}
          </div>
        </main>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Sidebar;
