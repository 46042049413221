import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { get, put } from "../../../utils/api/apiHelper";

const UpdateLimits = () => {

  const [mini, setMin] = useState({});
  const [maxi, setMax] = useState({});
  const [multi, setMulti] = useState({});
  const [tierData, setTierData] = useState([]);
  const [data,setData]=useState([]);

  const getTierData = async () => {
    try {
      const response = await get(
        `/admin/get_tier_settings`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        setTierData(response?.data);

        console.log("data========", tierData);
      } else {
        console.error("Failed to fetch data!");
      }
    } catch (error) {
      console.error("Something went wrong!", error);
    }
  };

  useEffect(() => {
    getTierData();
  }, []);


  const updateData = async (tier) => {
    try {
      const params = {
        multiplier: multi[tier] || "",
        max_points: maxi[tier]  || "",
        min_points: mini[tier]  || "",
        tier: tier,
      };
      const response = await put(
        `/admin/update/tier_setting`,
        params,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        toast.success("Update Data Successfully");
        setData({[tier]:""});
        setMin((prev) => ({ ...prev, [tier]: "" }));
        setMax((prev) => ({ ...prev, [tier]: "" }));
        setMulti((prev) => ({ ...prev, [tier]: "" }));
        getTierData();
      } else {
        console.error("Update Data Failed");
      }
    } catch (error) {
      console.error("Something went wrong!", error);
    }
  };
  return (
    <div>
      <div className="colorback rounded-4 ">
        <div className="row p-5">
          <h4 className="text-white">update table</h4>
          <Table responsive className="border">
            <thead className="text-center">
              <tr>
                <th>Tier</th>

                <th>Min</th>

                <th>Max</th>

                <th>Multiplier</th>
                <th></th>
              </tr>
            </thead>
            {tierData.map((doc) => {
              return (
                <tbody className="">
                  <tr className="bg-transparent" key={doc}>
                    <td>{doc.tier}</td>


                  
                    <td className="text-center ">
                      <span className="me-2 ">{doc?.min_points}</span>
                      <input
                        type="text"
                        className="bg-transparent text-white border_gray rounded-2 w-25 "
                        value={mini[doc?.tier] || ""}
                        onChange={(e) => setMin(prev=>({...prev,[doc?.tier]:e.target.value}))}
                      />
                    </td>

                    <td className="text-center">
                      <span className="me-2">{doc?.max_points}</span>
                      <input
                        type="text"
                        className="bg-transparent  text-white border_gray rounded-2 w-25"
                        value={maxi[doc?.tier] || ""}
                        onChange={(e) => setMax(prev=>({...prev,[doc?.tier]:e.target.value}))}
                      />
                    </td>

                    <td className="text-center">
                      <span className="me-2">{doc.multiplier}</span>

                      <input
                        type="text"
                        className="bg-transparent  text-white border_gray rounded-2 w-25"
                        value={multi[doc?.tier]|| ""}
                        onChange={(e) => setMulti(prev=>({...prev,[doc?.tier]:e.target.value}))}
                      />
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-default update-btn p-1"
                        type="submit"
                        onClick={() => updateData(doc.tier)}
                      >
                        <span className="text-gradient h6">Update</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UpdateLimits;
