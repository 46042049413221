import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { showSlicedText, switchHandler } from '../../../utils/common';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
const ApproveModal = (props) => {
  const approveWithdraw = async () => {
    try {
      props?.setLoading(true);
      await switchHandler();
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner();
      let tx = {
        to: props?.item?.user?.walletAddress,
        value: ethers.utils.parseEther(`${Number(props?.item?.amount).toFixed(4)}`),
      }
      console.log(tx);
      let signedTransaction = await signer.populateTransaction(tx);
      console.log(signedTransaction, "signedTransaction");
      let transaction = await signer.sendTransaction(signedTransaction);
      console.log(transaction, "transaction");
      let receipt = await transaction.wait();
      console.log(receipt, 'receipt')
      await props.saveTrx(props?.item?._id, receipt);
      props?.setLoading(false);
    } catch (error) {
      console.log(error, 'error');
      props?.setLoading(false);
      let shortMessage = error?.message?.split('.')?.[0];
      toast.error(shortMessage ?? "Transaction Failed !")
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className=''>
        <div className="">
          <div className="  ">
            <div className="row ">
              <div className="col-md-8 mx-auto text-white inner-content px-5 py-4 ">
                <Button variant="link" onClick={props.onHide} className='text-decoration-none float-end  light-text text-white '>X</Button>
                <h3 className="fs-3 text-center text-white py-4 mt-4">
                  Transaction Details
                </h3>
                <div className="mt-3 ">
                  <ListGroup className='bg-transparent'>
                    <ListGroup.Item className='bg-transparent text-white'>Amount :  {props?.item?.amount ?? 0}</ListGroup.Item>
                    <ListGroup.Item className='bg-transparent  text-white'>Wallet Address : {showSlicedText(props?.item?.user?.walletAddress ?? "")}</ListGroup.Item>
                  </ListGroup>
                </div>
                <div className='mt-3'>
                </div>
                <div className="text-center">
                  <Button className="px-4 py-2 m-3 approve border-0" onClick={() => approveWithdraw()}>Yes</Button>
                  <Button className="px-4 py-2  border-0" onClick={() => { }}>No</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ApproveModal