import "./Manual.scss";

import user from "../../assets/images/user.svg";
import eth from "../../assets/images/ethlogo.svg";
import bag from "../../assets/images/Handbag.svg";
import { IoInfinite } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";

import del from "../../assets/images/Trash.svg";
import horse from "../../assets/images/Horse.svg";
import crown from "../../assets/images/Crown.svg";
import cal from "../../assets/images/CalendarBlank.svg";
import photo from "../../assets/images/basephoto.svg";
// import pic from "../../assets/images/PictureInPicture.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { setLoading } from "../../redux/slices/commonSlice";
import { postAsForm } from "../../utils/api/apiHelper";

const Automatic = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);
  const [battle, setBattle] = useState({
    name: '',
    images: [null, null, null, null],
    bundles: [
      { price: '', entries: '' },
      { price: '', entries: '' },
      { price: '', entries: '' },
    ],
    jackpot_bundles: [
      { price: '', entries: '' },
      { price: '', entries: '' },
      { price: '', entries: '' },
    ],
    ticket_price: '',
    no_of_battles: '',
    jackpot_percentage: '',
    jackpot_ticket_price: '',
    no_of_jackpot_battles: '',
    requirements: {
      email: true,
      twitter: false,
      discord: false
    }
  });

  const createBattle = async () => {
    try {
      if (!Object.keys(battle?.requirements).some((key) => battle?.requirements?.[key])) {
        toast.error('Please select atleast one requirement !');
        return;
      }
      if (battle?.name === '') {
        toast.error('Please enter the name !');
        return;
      };
      if (Number(battle?.ticket_price) <= 0) {
        toast.error('Ticket Price must be greater than 0 !');
        return;
      };
      if (Number(battle?.no_of_battles) <= 0) {
        toast.error('No. of battles must be greater than 0 !');
        return;
      };
      if (Number(battle?.jackpot_ticket_price) <= 0) {
        toast.error('Jackpot Ticket Price must be greater than 0 !');
        return;
      };
      if (Number(battle?.no_of_jackpot_battles) <= 0) {
        toast.error('No. of jackpot battles must be greater than 0 !');
        return;
      };
      if (Number(battle?.jackpot_percentage) <= 0) {
        toast.error('Jackpot percentage must be greater than 0 !');
        return;
      };
      if (battle?.images.every(e => !e)) {
        toast.error('Atleast Add 1 Image !');
        return;
      }
      dispatch(setLoading(true));
      let {
        name,
        images,
        bundles,
        ticket_price,
        requirements,
        no_of_battles,
        jackpot_bundles,
        jackpot_percentage,
        no_of_jackpot_battles,
        jackpot_ticket_price
      } = battle;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("ticket_price", ticket_price);
      formData.append("no_of_battles", no_of_battles);
      formData.append("bundles", JSON.stringify(bundles));
      formData.append("jackpot_percentage", jackpot_percentage);
      formData.append("jackpot_ticket_price", jackpot_ticket_price);
      formData.append("no_of_jackpot_battles", no_of_jackpot_battles);
      formData.append("jackpot_bundles", JSON.stringify(jackpot_bundles));
      formData.append("requirements", JSON.stringify(requirements));
      for (const image of images) {
        if (image) {
          formData.append("images", image);
        }
      }
      const response = await postAsForm('/admin/automatic_battle', formData, localStorage.getItem('accessToken'))
      dispatch(setLoading(false));
      if (response.status === 200) {
        setBattle({
          name: '',
          images: [null, null, null, null],
          bundles: [
            { price: '', entries: '' },
            { price: '', entries: '' },
            { price: '', entries: '' },
          ],
          jackpot_percentage: '',
          jackpot_bundles: [
            { price: '', entries: '' },
            { price: '', entries: '' },
            { price: '', entries: '' },
          ],
          ticket_price: '',
          no_of_battles: '',
          requirements: {
            email: true,
            twitter: false,
            discord: false
          }
        });
        toast.success('Battle created successfully !')
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
      toast.error('Failed to create Battle !');
    }
  }
  const toggleRequirements = (type) => () => {
    let checked = battle.requirements[type];
    let requirements = { ...battle?.requirements, [type]: !checked };
    setBattle(prev => ({ ...prev, requirements }));
  };

  const handleBundleChange = (index, key, bundle_name = "bundles") => (e) => {
    let value = e?.target?.value;
    if (Number.isNaN(Number(value))) {
      return;
    }
    let bundles = battle[bundle_name];
    bundles[index] = { ...bundles[index], [key]: value };
    setBattle(prev => ({ ...prev, [bundle_name]: bundles }));
  }
  const handleRemoveBundle = (index, bundle_name = "bundles") => {
    let bundles = battle?.[bundle_name]?.filter((_, i) => i !== index);
    setBattle(prev => ({ ...prev, [bundle_name]: bundles }));
  }

  const handleAddBundle = (bundle_name = "bundles") => {
    let bundles = battle?.[bundle_name];
    bundles.push({ price: '', entries: '' });
    setBattle(prev => ({ ...prev, [bundle_name]: bundles }));
  }

  const handleInputChange = (key, type = "number") => (e) => {
    let value = e?.target?.value;
    if (type === "number" && Number.isNaN(Number(value))) {
      return;
    }
    setBattle(prev => ({ ...prev, [key]: value }));
  };

  const handleFileSelect = (index) => (e) => {
    let image = e?.target?.files?.[0];
    if (image) {
      let images = battle.images;
      images[index] = image;
      setBattle(prev => ({ ...prev, images }));
    } else {
      let images = battle.images;
      images[index] = null;
      setBattle(prev => ({ ...prev, images }));
      document.getElementById(`file-${index}`).value = null;
    }
  };

  return (
    <div className=" colorback pb-2 rounded-4 mt-4">
      <div className="row m-0 pb-0 ">
        <div className="col-lg-5 p-4">
          <h1 className="text-white">Automatic</h1>
        </div>

        <div className=" col-lg-6 social ">
          <h5 className="text-white mt-3 req text-uppercase">Requirement</h5>
          <ul className='list-unstyled d-flex flex-md-wrap flex-wrap  justify-content-between '>
            <li>
              <div className="form-check py-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={battle?.requirements?.email}
                  onClick={toggleRequirements('email')}
                  onChange={() => { }}
                  id="Check1"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check1">
                  Email
                </label>
              </div>
            </li>
            {/* <li>
              <div className="form-check  py-2 ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={battle?.requirements?.twitter}
                  onClick={toggleRequirements('twitter')}
                  onChange={() => { }}
                  id="Check2"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check2">
                  Twitter
                </label>
              </div>
            </li>
            <li>
              <div className="form-check   py-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={battle?.requirements?.discord}
                  onClick={toggleRequirements('discord')}
                  onChange={() => { }}
                  id="Check3"
                ></input>
                <label className="form-check-label text-white" htmlFor="Check3">
                  Discord
                </label>
              </div>
            </li> */}
          </ul>
        </div>


        <hr className="text-gray" />
      </div>

      <form onSubmit={(e) => e.preventDefault()} className="p-4">
        <div className="form-row row text-white  ">
          <div className=" col-md-6 col-lg-4  labelsize position-relative mb-4  ">
            <img src={horse} alt="" width={18} className=" icon-edit" />
            <label className="pb-1 col-8">Battles every 24 hours</label>
            <input
              type="text"
              name="no_of_battles"
              className="form-control  text-white  bg-transparent form-control-lg border_gray"
              placeholder="Battle Every 24H"
              value={battle?.no_of_battles}
              onChange={handleInputChange('no_of_battles')}
            />
          </div>
          <div className="  col-md-6 col-lg-4 labelsize position-relative mb-4">
            <img src={crown} alt="" width={18} className="icon-edit" />
            <label className="pb-1 ">Jackpot battles every week</label>

            <input
              type="text"
              name="no_of_jackpot_battles"
              value={battle?.no_of_jackpot_battles}
              className="form-control form text-white bg-transparent form-control-lg border_gray "
              placeholder="Enter Battles Every Week"
              onChange={handleInputChange('no_of_jackpot_battles')}
            />
          </div>

          <div className=" col-md-6  col-lg-4 labelsize position-relative mb-4">
            <img src={cal} alt="" width={18} className="icon-edit" />
            <label className="pb-1">DAILY % to Jackpot</label>

            <input
              type="text"
              name="jackpot_percentage"
              value={battle?.jackpot_percentage}
              className="form-control form text-white bg-transparent form-control-lg border_gray "
              placeholder="Enter daily % to jackpot"
              onChange={handleInputChange('jackpot_percentage')}
            />
          </div>


          <div className="row form-row ">
            <div className=" col-md-6  form-row col-lg-4 labelsize position-relative mb-4">
              <img src={user} alt="" width={18} className="icon-edit " />
              <label className="pb-1">Name</label>

              <input
                type="text"
                className="form-control form text-white bg-transparent form-control-lg border_gray "
                placeholder="name"
                value={battle?.name}
                onChange={handleInputChange('name', "text")}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className=" col-md-6 col-lg-4 labelsize position-relative mb-4">
              <img src={eth} alt="" width={16} className='icon-edit' />
              <label className='pb-1 fs-6'>DAILY Ticket Price</label>
              <input
                type="text"
                name="ticket_price"
                className="form-control form text-white bg-transparent form-control-lg border_gray "
                placeholder="0.00 eth"
                value={battle?.ticket_price}
                onChange={handleInputChange('ticket_price')}
              />
            </div>
            <div className=" col-md-6 col-lg-4 labelsize position-relative mb-4">
              <img src={eth} alt="" width={16} className='icon-edit' />
              <label className='pb-1 fs-6'>Jackpot Ticket Price</label>
              <input
                type="text"
                name="jackpot_ticket_price"
                className="form-control form text-white bg-transparent form-control-lg border_gray "
                placeholder="0.00 eth"
                value={battle?.jackpot_ticket_price}
                onChange={handleInputChange('jackpot_ticket_price')}
              />
            </div>
          </div>
        </div>





        <div className="row ">
          <div className="col-lg-6">
            {/* <div className="form-check  m-3 ">
                <input
                  className="form-check-input "
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>

                <h6 className="text-white ">DAILY ticket price</h6>
              </div> */}
            {
              battle?.bundles?.map((bundle, i) => {
                return (
                  <div className="row mt-4 " key={i + Math.random()}>
                    <p className='text-white '>Bundle {i + 1}</p>
                    <div className="col-12 col-lg-5 mb-4 labelsize position-relative text-white">
                      <img src={bag} alt="" width={15} className="bag" />
                      <label className="pb-1">Price</label>
                      <input
                        type="text"
                        className="form-control text-white bg-transparent form-control-lg border_gray "
                        placeholder="0.08 eth"
                        value={bundle?.price}
                        onChange={handleBundleChange(i, "price")}
                      />
                    </div>
                    <div className="col-12 col-lg-5  mb-4 labelsize position-relative text-white">
                      <IoInfinite className="infi" />
                      <label className="pb-1">Entries</label>

                      <input
                        type="text"
                        className="form-control text-white bg-transparent form-control-lg border_gray "
                        placeholder="enties 40"
                        value={bundle?.entries}
                        onChange={handleBundleChange(i, "entries")}
                      />
                    </div>

                    {i > 0 && <div className=" col-12 col-lg-2 mt-4">
                      <div className=" ">
                        <button href="#" className="rounded-2 delete border_gray color-del " onClick={() => handleRemoveBundle(i)}>
                          <img src={del} alt="" width={24} className="deleteimg  " />
                        </button>
                      </div>
                    </div>}
                  </div>
                )
              })
            }

            {battle?.bundles?.length < 4 && <div className="mt-4">
              <button className="btn rounded-3 colorskyblue add-bundle" onClick={() => handleAddBundle()}>
                <span className="text-white add ">
                  <FiPlus />
                </span>
              </button>

              <span className="text-white p-2 labelsize">Add Bundle</span>
            </div>}
          </div>

          <div className="col-lg-6">
            {
              battle?.jackpot_bundles?.map((bundle, i) => {
                return (
                  <div className="row mt-4 " key={i + Math.random()}>
                    <p className='text-white '>Jackpot Bundle {i + 1}</p>
                    <div className="col-12 col-lg-5 mb-4 labelsize position-relative text-white">
                      <img src={bag} alt="" width={15} className="bag" />
                      <label className="pb-1">Price</label>
                      <input
                        type="text"
                        className="form-control text-white bg-transparent form-control-lg border_gray "
                        placeholder="0.08 eth"
                        value={bundle?.price}
                        onChange={handleBundleChange(i, "price", "jackpot_bundles")}
                      />
                    </div>
                    <div className="col-12 col-lg-5  mb-4 labelsize position-relative text-white">
                      <IoInfinite className="infi" />
                      <label className="pb-1">Entries</label>

                      <input
                        type="text"
                        className="form-control text-white bg-transparent form-control-lg border_gray "
                        placeholder="enties 40"
                        value={bundle?.entries}
                        onChange={handleBundleChange(i, "entries", "jackpot_bundles")}
                      />
                    </div>

                    {i > 0 && <div className=" col-12 col-lg-2 mt-4">
                      <div className=" ">
                        <button href="#" className="rounded-2 delete border_gray color-del " onClick={() => handleRemoveBundle(i, "jackpot_bundles")}>
                          <img src={del} alt="" width={24} className="deleteimg  " />
                        </button>
                      </div>
                    </div>}
                  </div>
                )
              })
            }

            {battle?.jackpot_bundles?.length < 4 && <div className="mt-4">
              <button className="btn rounded-3 colorskyblue add-bundle" onClick={() => handleAddBundle("jackpot_bundles")}>
                <span className="text-white add ">
                  <FiPlus />
                </span>
              </button>

              <span className="text-white p-2 labelsize">Add Bundle</span>
            </div>}

          </div>

          <div className="row mt-5">
            <p className="text-white">Battle Image</p>
            {
              battle?.images?.map((image, i) => {
                return (
                  <div className="mt-4  col-md-3  fig" key={i * Math.random()}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect(i)}
                      id={`file-${i}`}
                      hidden={true}
                    />
                    <figure className="upload-figure  img-background rounded-4 position-relative">
                      {
                        image
                          ?
                          <>
                            <div className='remove'>
                              <ImCross
                                className=''
                                onClick={handleFileSelect(i)}
                              />
                            </div>
                            <label htmlFor={`file-${i}`} className='overflow-hidden h-100'>
                              <img
                                src={URL.createObjectURL(image)}
                                className='image'
                                alt=""
                              />
                            </label>
                          </>
                          : (
                            <label htmlFor={`file-${i}`}>
                              < img src={photo} alt="" className='photo' />
                            </label>
                          )
                      }
                    </figure>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="text-center mt-4 pb-5">
          {/* <button className="btn border_gray btn-upload ">
            <span className="btn-file  text-white">
              <span>
                <img src={pic} alt="" />
              </span>
              Upload Image <input type="file" />
            </span>
          </button> */}

          <div className="mt-5 ">
            <button className="btn btn-default new-btn  btn-width" onClick={createBattle} disabled={loading}>
              <span className="text-gradient">{loading ? "Creating..." : "Create"}</span>
            </button>
          </div>

        </div>
      </form>
    </div>
  );
};

export default Automatic;
