import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { get,  remove } from "../../utils/api/apiHelper";
import "./subscribe.scss";
import PaginationComponent from "../Pagination";

  const Subscribe = () => {
  const [email, setEmail] = useState([]);
  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);

  const deleteSubscriber = async (id) => {
    setLoading(true);
    try {
      let response = await remove(
        `/admin/remove_subscriber/${id}`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        toast.success(response?.message);
        getEmail();
      } else {
        toast.error(response?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
    setLoading(false);
  };
  const getEmail = async () => {
    try {
      let response = await get(
        `/admin/getSubscriptions?pageNumber=${currentPage}&pageSize=${perPage}&email=${email}`,
        localStorage.getItem("accessToken")
      );
      if (response.status === 200) {
        setEmail(response.data.items);
        setTotal(response.data.totalItems);
      } else {
        toast.error("Failed to fetch data!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getEmail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  if (loading) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <div className="custom-loader"></div>
      </div>
    );
  }
  return (
    <div className="colorback rounded-4">
      <div className="p-4 ">
        <h1 className="text-cyan">Subscriber List </h1>
        <Table responsive="md" className="table  mt-4 ">
          <thead className="thead-dark">
            <tr>
              <th scope="col" className="h5 ">
                S/No.
              </th>
              <th scope="col" className="h5">
                Subscriber User{" "}
              </th>
              <th></th>
            </tr>
          </thead>
          {email.map((printItem, i) => (
            <tbody className="">
              <tr className="">
                <td>{i + 1}</td>
                <td>{printItem.email}</td>

                <td>
                  <button
                    className="btn btn-default border  border_gray shadow-0  text-md-center  
                          bg-dark-1 mb-0 w-100  edit_size cheak "
                    onClick={() => deleteSubscriber(printItem._id)}
                  >
                    <span className="text-white   text-center   ">Remove</span>
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>

        <div className=" mt-5  subs">
          {total > 0 && (
            <PaginationComponent
              className="mx-auto"
              itemsCount={total}
              itemsPerPage={perPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
