import { ethers } from 'ethers';
import moment from 'moment';

export const getImageSrc = (image_name) => {
    return `https://api.chainwarz.com/uploads/${image_name}`
    // return `http://localhost:4401/uploads/${image_name}`
}
export const downloadCSV = (data, filename) => {
    const url = URL.createObjectURL(new Blob([data], { type: `text/csv` }));
    const a = document.createElement('a')
    a.href = url;
    a.setAttribute('download', `${filename}_${Date.now()}.csv`)
    a.click()
}

export const fullDateFormat = (date) => {
    return (
        <>
            {moment(date).format('MMMM DD, YYYY')}
            <br />
            <small>{moment(date).format('h:mm a')}</small>
        </>
    )
}

export function showSlicedText(text) {
    return text.length > 12 ? [text?.slice(0, 4), text.slice(text?.length - 4)].join('...') : text;
}

export const switchHandler = async () => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("wallet_switchEthereumChain", [{
            chainId: '0xaa36a7'
        }]).catch((err) => console.log(err))
        return provider;
    } else {
        throw new Error("Please Install Metamask !!");
    }
}

export const LINK_ADDRESS = '0x779877A7B0D9E8603169DdbD7836e478b4624789';