import user from "../../assets/images/users.svg";
import usercircle from "../../assets/images/UserCircle.svg";
import "./Dashboard.scss";
import wallet from "../../assets/images/Wallet.svg";
import metamask from "../../assets/images/Metamask.svg";
import coinbase from "../../assets/images/Coinbase.svg";
import walletconnect from "../../assets/images/WalletConnect.svg";
import hash from "../../assets/images/Hash.svg";
import hand from "../../assets/images/Handshake.svg";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import map from "../../assets/images/map.png";
import eth from "../../assets/images/ethlogo.svg";
import PrizeAllocationChart from "./prizeAllocationChart";
import game from "../../assets/images/game.png";
// import { useEffect, useState } from "react";
import { get } from "../../utils/api/apiHelper";
import React, { useState, useEffect } from 'react';
import Overview from "../chart/ColumnChart";

const Dashboard = () => {
  const [stats, setStats] = useState({});
  const [selectedInterval, setSelectedInterval] = useState('year');
console.log(stats,'stats');
  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await get(`/dashboard/dashboardAnalytics?selected=${selectedInterval}`, localStorage.getItem('accessToken'));
        
        if (response.status === 200) {
          // console.log(response.data, 'ds');
          // console.log(response.data.graphData, 'Graph Data');
          setStats(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getStats();
  }, [selectedInterval]);

  
  return (
    <div className="main-inner">
      <div className="bg-dark-1 mt-4">
        <div className="row ">
          <div className="col-md-6 text-start">
            <div className="colorback p-4 rounded-4">
              <span className=" rounded-2 imgedit">
                <img src={user} width={30} alt="" />
              </span>
              <span className="text-white text-sm-h6 p-2">Online Users</span>
              <div className="text-end ">
                <img src={usercircle} width={35} alt="" className="" />
                <span className="text-white h3 text-md-h6">{stats?.totalUser ?? 0}</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-start">
            <div className="colorback p-4 rounded-4">
              <span className=" rounded-2 imgedit">
                <img src={wallet} width={30} alt=""/>
              </span>
              <span className="text-white text-sm-h6 p-2">Number of wallets</span>
              <div className="text-end ">
                <img src={wallet} width={25} alt=""/>
                <span className="text-white h3 text-md-h6">{stats?.totalwalletConnected ?? 0}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          {/* <div className="col-lg-3  ">

            <div className="colorback rounded-4 p-1 pb-3">
              <div className="p-1 mt-3 ">
                <span className="rounded-2 imgedit">
                  <img src={wallet} width={25} alt="" className="" />
                </span>

                <span className="text-white tsize"> Number of wallets</span>

                <hr className="text-gray" />
              </div>

              <div className="p-1 mb-5">
                <ul className="list-unstyled   ">
                  <li>
                    <img src={metamask} width={50} alt="" className="pb-2" />
                    <span className="text-white h3 p-3">{stats?.metamask ?? 0}</span>
                  </li>

                  <li>
                    <img src={coinbase} width={50} alt="" className="pb-2" />
                    <span className="text-white h3 p-3">{stats?.coinbase ?? 0}</span>
                  </li>

                  <li>
                    <img src={walletconnect} width={50} alt="" className="pb-2" />
                    <span className="text-white h3 p-3">{stats?.wallet_connect ?? 0}</span>
                  </li>
                </ul>
              </div>
            </div>

          </div> */}

          <div className=" col-lg-12 marginbottom  ">
            <div className=" colorback rounded-4 p-4">
              <div className=" d-flex  ">
                <div>
                  <span className=" rounded-2 imgedit  ">
                    <img src={hash} width={25} alt="" className="" />
                  </span>

                  <span className="text-white p-1 "> Stakers</span>

                  <span className="vr  text-white "></span>
                </div>

                <div className="  px-2 ">
                  <img src={hand} width={25} alt="" className="" />
                  <span className="text-white"> 6869</span>
                </div>

                <span className="text-white ms-auto "> 88%</span>
              </div>

              <span>
                <hr className="text-gray " />
              </span>

              <div className="row">
                <div className=" col-md-4 text-center ">
                  <h5 className="textorange text-center ">60 days</h5>

                  <div className="progress orange  mx-auto ">
                    <span className="progress-left">
                      <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value">
                      90%
                      <h6 className="p-value text-white">Stakers</h6>
                    </div>
                  </div>
                </div>

                <div className=" col-md-4  ">
                  <h5 className="textellic text-center ">90 days</h5>

                  <div className="progress ellic  mx-auto ">
                    <span className="progress-left">
                      <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar"></span>
                    </span>
                    <div className="progress-ellic">
                      30%
                      <h6 className="p-value text-white">Stakers</h6>
                    </div>
                  </div>
                </div>

                <div className=" col-md-4  ">
                  <h5 className="text-primary text-center ">60 days</h5>

                  <div className="progress blue  mx-auto ">
                    <span className="progress-left">
                      <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar"></span>
                    </span>
                    <div className="progress-blue">
                      90%
                      <h6 className="p-value text-white">Stakers</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="colorback rounded-4 p-2">
          <div className="row ">
            <div className="p-3  d-md-flex">
              <span className="rounded-2 imgedit img">
                <img src={user} width={25} alt="" className="" />
              </span>
              <span className="text-white p-2 prizeall">Prize Allocation</span>

              <div className="ms-auto btngroup ">
                <ButtonGroup size="sm" className="  border-color   p-1">
                  <Button variant="" className="text-white" onClick={() => setSelectedInterval('day')}>
                    Day
                  </Button>
                  <Button variant="" className="text-white" onClick={() => setSelectedInterval('month')}>
                    Month
                  </Button>
                  <Button variant="" className="text-white" onClick={() => setSelectedInterval('year')}>
                    Year
                  </Button>
                </ButtonGroup>

                <DropdownButton
                  title="Team  "
                  as={ButtonGroup}
                  className="drop-btn   px-1"
                >
                  <Dropdown.Item eventKey="1">Demo</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <span> <hr className="text-gray" /></span>
            <div className="row">
              <div className=" col-lg-9">
                {/* <img src={graph} alt="" /> */}
                {/* <PrizeAllocationChart /> */}
                <Overview graphData={stats?.graphData}/>
                {/* <PrizeAllocationChart graphData={stats?.data?.graphData || []} /> */}

              </div>

              <div className=" col-lg-3">
                <ul className="list-unstyled ">
                  <li className="mt-4  d-flex align-items-center">
                    <div className="col-3">
                      <span className=" box-edit rounded-2 ">
                        <img src={eth} alt="" />
                      </span>
                    </div>

                    <div className="col-9">
                      <span className="text-white ">Winner</span>
                      <p className="text-white fs-6 mb-0">{stats?.graphData?.length}</p>

                    </div>
                  </li>


                  <li className="mt-4  d-flex align-items-center">
                    <div className="col-3">
                      <span className=" box rounded-2 ">
                        <img src={eth} alt="" />
                      </span>
                    </div>

                    <div className="col-9">
                      <span className="text-white ">Stakers</span>
                      <p className="text-white fs-6 mb-0">0</p>

                    </div>
                  </li>

                  <li className="py-4  d-flex align-items-center">
                    <div className="col-3">
                      <span className=" box3 rounded-2 ">
                        <img src={eth} alt="" />
                      </span>
                    </div>

                    <div className="col-9">
                      <span className="text-white ">Team</span>
                      <p className="text-white fs-6 mb-0">0</p>

                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">

          <div className="  col-lg-8 ">
            <div className="colorback rounded-4 mt-3 p-3">
              <div>
                <span className="rounded-2 imgedit img">
                  <img src={user} width={25} alt="" className="" />
                </span>
                <span className="text-white p-2">Jurisdiction</span>
              </div>

              <hr className="text-gray" />

              <img src={map} alt="" />
            </div>
          </div>


          <div className="col-lg-4  mt-3">
            <div className="game p-2 rounded-4 pb-4">
              <span>
                <img src={game} alt="" />
              </span>

              <span className="text-white p-2">battle played</span>
              <hr className="text-white " />

              <div className="text-center games">
                <span className="text-white  battle_P h4">Played :</span>
                <span className="text-white  battle_P h4">{stats?.totalBattlePlayed ?? 0}</span>

                <p className="text-white p-2 ">
                  won to date:
                  <span className="">
                    <img src={eth} width={12} alt="" className="" />

                    <span className="p-1">576.28</span>
                  </span>
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};

export default Dashboard;
