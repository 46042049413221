
import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Main from './components/Main/Main';
import Dashboard from './components/Dashboard/Dashboard';
import WinnerHistory from './components/WinnerHistory/WinnerHistory';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Policy from './components/Policy/Policy';
import Editschedule from './components/EditSchedule/EditSchedule';
import CreateBattle from './components/CreateBattle/CreateBattle';
import Users from './components/Users/Users';
import WinnerVerification from './components/WinnerVerification/WinnerVerification'
import Login from './components/Login/Login';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom';
import Loader from './components/Loader';
import Pagenotfound from './components/pagenotfound/pagenotfound.component';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import MyProfile from './components/MyProfile/MyProfile';
import { get } from './utils/api/apiHelper';
import { useDispatch } from 'react-redux';
import { login } from './redux/slices/userSlice';
import Subscribe from './components/UserSubscription/Subscribe';
import UpdatePoints from './components/Settings/UpdatePoints/UpdatePoints';
import UpdateLimits from './components/Settings/UpdateMinMax/UpdateLimits';
import Refunds from './components/RefundHistory/RefundHistory';


function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    const checkAuth = async () => {
      let token = localStorage.getItem('accessToken');
      let response = await get('/admin/profile', localStorage.getItem('accessToken'));
      if (response?.status === 200) {
        dispatch(login({ ...response.data, token }));
      } else {
        navigate("/login");
      }
    }

    if (!localStorage.getItem('accessToken')) {
      navigate('/login');
    } else {
      checkAuth()
    }
   
  }, [navigate,dispatch]);
  if (!localStorage.getItem('accessToken')) {
    return;
  }
  return (
    <React.Fragment>
      <Header />
      <Main >
        <Outlet />
        <Footer />
      </Main>
    </React.Fragment>
  );
}

function App() {
  return (
    <div className="App bg-dark-1 mhv-100 position-relative">
      <Loader />
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>

          <Route path='/' element={<Layout />}>
            <Route index element={<Dashboard />}></Route>
            <Route path="create-battle" element={<CreateBattle />}></Route>
            <Route path="allowlist" element={<WinnerVerification />}></Route>
            <Route path="scheduled-battles" element={<Editschedule />}></Route>
            <Route path="users" element={<Users />}></Route>
            <Route path="terms" element={<Policy />}></Route>
            <Route path="privacy" element={<Policy />}></Route>
            <Route path="winners" element={<WinnerHistory />}></Route>
            <Route path="refunds" element={<Refunds />}></Route>
            <Route path="my-profile" element={<MyProfile />}></Route>
            <Route path="update-settings" element={<UpdatePoints />}></Route>
            <Route path="update-limits" element={<UpdateLimits />}></Route>
            <Route path="subscriber-list" element={<Subscribe />}></Route>
          </Route>
          <Route path="*" element={<Pagenotfound />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
